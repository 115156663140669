import { IDisplayStrategy } from './IDisplayStrategy';

export interface IRevDisplay {
	admin: IAdminDisplay;
	header: IHeaderDisplay;
	scheduledEvents: IScheduledEventsDisplay;
	defaultLogoUriKey: string;
}

export interface IScheduledEventsDisplay {
	hideAddEvent: boolean;
	analyticsRedirect: boolean;
	realTimeAnalyticsSettings: IRealTimeAnalyticsDisplay
	hideWebcastLink: boolean;
	hideEmbedWebcastButton: boolean;
	hideRecordingField: boolean;
	hideRedirectField: boolean;
	hideSettingsButton: boolean;
	hideDuplicateButton: boolean;
	hideReportsButton: boolean;
}

export interface IRealTimeAnalyticsDisplay {
	hideHeaderLogo: boolean;
	hideUserIdColumn: boolean;
	hideFullnameColumn: boolean;
	hideEmailColumn: boolean
}

export interface IAdminDisplay {
	mediaSettings: IMediaSettingsDisplay;
	securitySettings: ISecuritySettingsDisplay;
	integrationSettings: IIntegrationDisplay;
	zoneSettings: IZoneSettingsDisplay;
}

export interface IMediaSettingsDisplay {
	hideEnableCategories: boolean;
	hideEnableTags: boolean;
	hideAllowPolls: boolean;
	hideAllowQA: boolean;
	hideAllowChat: boolean;
	hideSendLinkToAttendees: boolean;
	hideVideoSettings: boolean
}

export interface IZoneSettingsDisplay {
	hideFullRev: boolean;
	hideVbrickRevSettings: boolean;
}

export interface ISecuritySettingsDisplay {
	hideWebcastRecording: boolean;
}

export interface IHeaderDisplay {
	hideManageSubscriptions: boolean;
}

export interface IIntegrationDisplay {
	hideAdditionalWebcastSources: boolean;
	hideAi: boolean;
	hidePexip: boolean;
	hideVci: boolean;
	hideWebexMeetings: boolean;
	hideWebexWebinars: boolean;
	hideWebexSpark: boolean;
	hideZoomMeetings: boolean;
	hideMsIntegration: boolean;
	hideMsVci: boolean;
	hideMsRampEcdn: boolean;
}

export function getRevDisplay(isDistribution: boolean, isEcdn: boolean): IRevDisplay {
	return {
		admin: {
			mediaSettings: {
				hideEnableCategories: isDistribution,
				hideEnableTags: isDistribution,
				hideAllowPolls: isDistribution,
				hideAllowQA: isDistribution,
				hideAllowChat: isDistribution,
				hideSendLinkToAttendees: isDistribution,
				hideVideoSettings: isEcdn
			},
			securitySettings: {
				hideWebcastRecording: isDistribution
			},
			integrationSettings: {
				hideAdditionalWebcastSources: isDistribution,
				hideAi: isEcdn,
				hidePexip: isEcdn,
				hideVci: isEcdn,
				hideWebexMeetings: isEcdn,
				hideWebexWebinars: isEcdn,
				hideWebexSpark: isEcdn,
				hideZoomMeetings: isEcdn,
				hideMsIntegration: isEcdn,
				hideMsVci: isEcdn,
				hideMsRampEcdn: !isEcdn
			},
			zoneSettings: {
				hideFullRev: isEcdn,
				hideVbrickRevSettings: isEcdn
			}
		},
		header: {
			hideManageSubscriptions: isDistribution
		},
		scheduledEvents: {
			hideAddEvent: isEcdn,
			analyticsRedirect: isEcdn,
			hideWebcastLink: isEcdn,
			hideEmbedWebcastButton: isEcdn,
			hideRecordingField: isEcdn,
			hideRedirectField: isEcdn,
			hideSettingsButton: isEcdn,
			hideDuplicateButton: isEcdn,
			hideReportsButton: isEcdn,
			realTimeAnalyticsSettings: {
				hideHeaderLogo: isEcdn,
				hideUserIdColumn: !isEcdn,
				hideFullnameColumn: isEcdn,
				hideEmailColumn: isEcdn
			}
		},
		defaultLogoUriKey: isEcdn ? 'ecdnUrl' : 'url'
	};
}

export class RevDisplayContext {
	private strategy: IDisplayStrategy;

	constructor(strategy: IDisplayStrategy) {
		this.setStrategy(strategy);
	}

	public setStrategy(strategy: IDisplayStrategy) {
		this.strategy = strategy;
	}

	public getDisplay(): IRevDisplay {
		return this.strategy.getDisplay();
	}
}
