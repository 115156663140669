import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { sortBy } from 'underscore';
import { isString, deepMergeObjects, noop } from 'rev-shared/util';

import { extractQueryParamsFromHash } from 'rev-shared/util/UrlUtil';

import {
	IRevConnectInfo,
	IRevConnectLoggingInfo
} from '@vbrick/vbrick-rev-connect/src/IRevConnectInfo';
import { Subscription } from 'rxjs';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import { ITranslationStrings } from 'rev-shared/util/ITranslationStrings';
import { TranslationsRequestService } from 'rev-shared/util/TranslationsRequest.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { DualPlaybackSourceTypes } from 'rev-shared/media/VideoSourceType';
import { getUrlPath } from 'rev-shared/util/Util.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { IVbPlaybackTokenConfig } from 'vbrick-player-src/IVbPlaybackTokenConfig';
import { HlsUtilService } from 'vbrick-player-src/HlsUtil.Service';
import { LanguageNames } from 'vbrick-player-src/LanguageNames';
import { SupportedPlaybacksService } from 'vbrick-player-src/SupportedPlaybacks.Service';
import { VG_CONFERENCE_DUAL_PLAYBACK_EXTENSION } from '@vbrick/vbrick-player-conference-dual-hls-plugin/DualHlsConstants';

import { MimeTypes } from './MimeTypes';
import { IModernPlayback } from './IModernPlayback';
import { getPlaybackToken, PLAYBACK_TOKEN_END_POINT, useWithCredentials } from './PlaybackTokenUtility';
import { ResourceType } from './ResourceType';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { getOmniCachePlaybackUrl, getPlaybackOmniCacheNode } from './OmniCacheNodeSelector';
import { IPlaybackCacheNode } from './IPlaybackCacheNode';
import { first } from 'rxjs/operators';

const PLAYER_CSS_VARIABLE = {
	accentColor: '--player-accent-color'
};

export enum StreamType {
	FLV = 'FLV',
	H264 = 'H264',
	HLS = 'HLS',
	MP4 = 'MP4',
	MULTICAST = 'Multicast',
	RTMP = 'RTMP'
}

export interface IModernSubtitle {
	label?: string;
	src: string;
	language: string;
}

export interface IPlaybackCfg {
	player?: string;
	url?: string;
	srcObject?: any;
	videoFormat: string;
	isDefault?: boolean;
	revConnectInfo?: any;
	qValue?: any;
	selected?: boolean;
}

const REV_CONNECT_TOKEN_RESOURCE_AUD: string = 'rev_connect';
const tokenRenewalIntervalInMins: number = 60;

@Injectable({
	providedIn: 'root'
})
export class VideoPlayerAdapterService {
	private playerTranslations: ITranslationStrings | Promise<ITranslationStrings>;
	private playerSubscription: Subscription;

	constructor(
		private HlsUtilService: HlsUtilService,
		private SupportedPlaybacks: SupportedPlaybacksService,
		private TranslationsRequest: TranslationsRequestService,
		private UserContext: UserContextService,
		private http: HttpClient,
		private ThemeService: ThemeService,
		private MediaFeatures: MediaFeaturesService
	) {}

	public convertToModernPlayerPlaybacks(resourceId: string, resourceType: ResourceType, playbacks: IPlaybackCfg[], isLive: boolean, videoSourceType: string, is360Enabled: boolean = false, revConnectLoggingInfo?: IRevConnectLoggingInfo, disableDualPlayback?: boolean, cacheNodes?: IPlaybackCacheNode[]): Promise<IModernPlayback[]> {
		return this.getPlayerTranslations()
			.then(playerTranslations => {
				let modernPlaybacks =
					playbacks
						.filter(playback => playback.player !== 'Vbrick') // filter out playbacks for the legacy player
						.map(playback => {
							const src = playback.url || '';
							const tokenConfig = this.shapeTokenConfig(resourceId, resourceType, src);
							const type = playback.srcObject
								? MimeTypes.MP4
								: this.getModernPlayerPlaybackType(playback.videoFormat, src);
							const token = this.UserContext.getUser().token;
							return {
								is360Enabled,
								isDefault: playback.isDefault,
								isLive,
								label: this.getPlaybackLabel(playback, type, playerTranslations),
								originalSrc: src,
								qValue: playback.qValue,
								revConnectInfo: this.shapeRevConnectInfo(resourceId, playback.revConnectInfo, revConnectLoggingInfo, src),
								src,
								srcObject: playback.srcObject,
								type,
								tokenConfig,
								authHeader: token?.accessToken && `vbrick ${token.accessToken}`
							};
						});

				//if video conference source, prepend dual entries for all m3u8 entires (this is a player-specific interpretation of standard hls)
				if (!disableDualPlayback && DualPlaybackSourceTypes.includes(videoSourceType)) {
					const dualPlaybacks = modernPlaybacks
						.filter(playback => playback.src.includes('.m3u8'))
						.map(hlsPlayback => Object.assign({}, hlsPlayback, {
							type: MimeTypes.DUAL,
							src: hlsPlayback.src.replace('.m3u8', VG_CONFERENCE_DUAL_PLAYBACK_EXTENSION)
						}));

					modernPlaybacks = dualPlaybacks.concat(modernPlaybacks);
				}

				return modernPlaybacks;
			})
			.then(mPlaybacks => this.shapePlaybacksByEcdn(mPlaybacks, cacheNodes, isLive));
	}

	public convertToModernSubtitles(subtitles: Array<{ languageCode: string; src: string }>): IModernSubtitle[] {
		return sortBy(subtitles?.map(subtitle => {
			return {
				label: LanguageNames[subtitle.languageCode],
				src: subtitle.src,
				language: subtitle.languageCode
			};
		}), 'language');
	}

	private getModernPlayerPlaybackType(videoFormat: string, src: string): MimeTypes {
		const isRtmp = src.startsWith('rtmp://');

		if (this.HlsUtilService.isMulticast(src, null)) {
			videoFormat = this.HlsUtilService.TYPE_VBRICK_MULTICAST;
		} else if (isRtmp || src.startsWith('rtmfp://') || src.includes('.f4m')) {
			return null;
		} else if (src.includes('.m3u8')) { //back-end returns the wrong format for HLS
			videoFormat = 'HLS';
		} else if (src.includes('.flv') || src.includes('.f4v')) {
			videoFormat = 'FLV';
		} else if (src.includes('.m4v')) { //mpeg4-part2
			videoFormat = 'Mpeg4';
		}

		if (!isString(videoFormat)) {
			return;
		}

		switch (videoFormat.trim().toLowerCase()) {
			case 'h264':
			case 'mpeg4':
				return MimeTypes.MP4;

			case 'hls':
				return MimeTypes.HLS;

			case 'flv':
				return MimeTypes.FLV;

			default:
				return null;
		}
	}

	public getPlayerTranslations(): Promise<any> {
		if (this.playerTranslations) {
			return Promise.resolve(this.playerTranslations);
		}

		return this.playerTranslations = this.TranslationsRequest.requestTranslations('/partials/shared/media-player/vbrick-player-translations.html', 'vbrickPlayerTranslations')
			.then(translations => this.playerTranslations = translations);
	}

	private getStreamType(videoUrl: string, videoFormat: string): StreamType {
		videoUrl = videoUrl.trim().toLowerCase();
		videoFormat = videoFormat?.trim().toLowerCase();

		if (this.SupportedPlaybacks.isMulticastSource(videoUrl, '')){
			return StreamType.MULTICAST;
		} else if(videoUrl.startsWith('rtmp://')){
			return StreamType.RTMP;
		}

		const mimeType = this.getModernPlayerPlaybackType(videoFormat, videoUrl);

		switch (mimeType) {
			case MimeTypes.MP4:
				return StreamType.MP4;
			case MimeTypes.HLS:
				return StreamType.HLS;
			case MimeTypes.FLV:
				return StreamType.FLV;
			default:
				return null;
		}
	}

	public getPlaybackForUrl(url: string, modernPlaybacks: any[], rawPlaybacks: any[], isRevConnect: boolean = false): any {
		const modernPlayback = (modernPlaybacks || []).find(playback => url === playback.src && !!playback.revConnectInfo === isRevConnect); //compensate for query token and any other modifications

		if (modernPlayback) {
			const playback = (rawPlaybacks || []).find(playback => modernPlayback.originalSrc === playback.url && !!playback.revConnectInfo === isRevConnect);
			const streamType = this.getStreamType(url, playback?.videoFormat);

			//return a copy of the original playback with the streamType corrected
			return Object.assign({}, playback, { streamType });
		}

		return null;
	}

	public setPlayerStyle(element: HTMLElement, accentColor?: string): void {
		if (accentColor) {
			element.style.setProperty(PLAYER_CSS_VARIABLE.accentColor, accentColor);
			return;
		}

		this.playerSubscription = this.ThemeService.brandingSettings$
			.subscribe(brandingSettings => element.style.setProperty(PLAYER_CSS_VARIABLE.accentColor, brandingSettings.themeSettings.accentColor));
	}

	public cleanup(): void {
		this.playerSubscription?.unsubscribe();
	}

	private getPlaybackLabel(playback: any, type: MimeTypes, playerTranslations: any): string {
		if (type === MimeTypes.HLS) {
			return playerTranslations.hlsPlaybackMenuOption;
		} else if (playback.label === '-') {
			return playerTranslations.originalPlaybackMenuOption;
		}

		return playback.label;
	}

	private shapeTokenConfig(resourceId: string, resourceType: ResourceType, src: string): IVbPlaybackTokenConfig {
		const tokenType = getPlaybackToken(src);

		if(!tokenType) {
			return;
		}

		const cnodeFound = src.match(/cnode=([^&]*)/);
		const cnode = cnodeFound ? cnodeFound[1] : '';

		return {
			path: getUrlPath(src),
			renewalIntervalInMins: tokenRenewalIntervalInMins,
			resourceId,
			resourceType,
			tokenName: tokenType,
			cnode,
			tokenEndpoint: PLAYBACK_TOKEN_END_POINT,
			withCredentials: useWithCredentials(src)
		};
	}

	private shapeRevConnectInfo(resourceId: string, revConnectInfo: IRevConnectInfo, loggingInfoInput: IRevConnectLoggingInfo, originalSrc: string): any {
		if (!revConnectInfo) {
			return undefined;
		}

		//This is only needed in qa env to test rev connect performance.
		const rcFromUrl = this.MediaFeatures.accountFeatures?.enableRevTestModeFeatures
			&& this.readRevConnectInfoFromUrl();

		if (rcFromUrl) {
			revConnectInfo = deepMergeObjects(revConnectInfo, rcFromUrl);
		}

		const loggingInfo: IRevConnectLoggingInfo = { ...loggingInfoInput };

		if (loggingInfo && loggingInfo.remoteLoggingConfig) {
			loggingInfo.remoteLoggingConfig.context.originUrl = originalSrc;
		}

		const output = {
			...revConnectInfo,
			loggingInfo
		};

		output.jwtToken.getToken = () => this.getRevConnectSignalingToken(resourceId, revConnectInfo.zoneInfo.zoneId);

		return output;
	}

	private getRevConnectSignalingToken(resourceId: string, zoneId: string): Promise<string> {
		return lastValueFrom(this.http.get<{ token: string }>('/auth/token', {
			params: {
				aud: REV_CONNECT_TOKEN_RESOURCE_AUD,
				resourceId,
				zoneId
			}
		}))
			.then(result => result.token);
	}

	private readRevConnectInfoFromUrl(): any {
		const { rcInfo } = extractQueryParamsFromHash();
		return rcInfo ? JSON.parse(window.atob(rcInfo)) : undefined;
	}

	private shapePlaybacksByEcdn(mPlaybacks: IModernPlayback[], cacheNodes: IPlaybackCacheNode[], isLive: boolean): Promise<IModernPlayback[] | any> {
		if(cacheNodes?.length) {
			return this.shapePlaybackByRampEcdn(mPlaybacks, cacheNodes, isLive);
		}

		//No change for vbrick eCDN.
		return Promise.resolve(mPlaybacks);
	}

	private shapePlaybackByRampEcdn(mPlaybacks: IModernPlayback[], cacheNodes: IPlaybackCacheNode[], isLive: boolean): Promise<IModernPlayback[] | any> {
		//for now only OmniCache.
		return lastValueFrom(
			getPlaybackOmniCacheNode(this.http, cacheNodes)
				.pipe(
					first(val => !!val)
				)
		).then(selectedNode => {
			let newPlaybacks = mPlaybacks.map(playback => ({ ...playback, src: getOmniCachePlaybackUrl(playback.src, selectedNode.url) }));

			//add fallback for vod.
			if (!isLive) {
				newPlaybacks = newPlaybacks.concat(mPlaybacks);
			}
			return newPlaybacks;
		}).catch(err => {
			console.error('Error while selecting OmniCache Node', err);
			if (!isLive) {
				return mPlaybacks;
			}
		});
	}
}
