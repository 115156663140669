import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { TransitionService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

import { UploadService } from 'rev-portal/media/import/Upload.Service';

import { BrowserFeatureService } from 'rev-shared/util/BrowserFeature.Service';
import { FileWrapper } from 'rev-shared/ui/fileUpload/FileWrapper';
import { ICancellableQueue, IPromiseQueue, createPromiseQueue, createThrottledQueue, race } from 'rev-shared/util/PromiseUtil';
import { ISessionKeepalive, SessionService } from 'rev-shared/security/Session.Service';
import { VideoSource } from 'rev-shared/media/VideoSource';
import { isMobileSafariWithDialogBlocking } from 'rev-shared/util/UserAgentUtil';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { noop } from 'rev-shared/util';

import { UploadFileService } from './UploadFile.Service';

@Component({
	selector: 'upload-file',
	templateUrl: './UploadFile.Component.html',
})
export class UploadFileComponent implements OnInit, OnDestroy {
	@Input() private maxFileUploadSizeBytes: number;
	@Input() private accountId: string;
	@Input() private persistState: boolean = false;
	@Input() public viewingHoursUnavailable: boolean;
	@Input() public chunkUploadSizeBytes: number;
	@Input() public features: any;
	@Input() public teamId: string;

	private videoCreateQueue: IPromiseQueue<any>;
	private uploadQueue: ICancellableQueue<any>;
	private unloadListeners: Array<() => void>;
	private isConfirmAllowed: boolean;

	public is360Video: boolean;
	public fileDragAndDropSupported: boolean;
	public noop = () => noop();

	constructor(
		public UploadService: UploadService,
		public UploadFileService: UploadFileService,
		private BrowserFeature: BrowserFeatureService,
		private Session: SessionService,
		private TranslateService: TranslateService,
		private $transitions: TransitionService
	) { }

	public ngOnInit(): void {
		this.fileDragAndDropSupported = this.BrowserFeature.fileDragAndDrop();
		this.uploadQueue = createPromiseQueue();
		this.videoCreateQueue = createThrottledQueue(3000);
		this.isConfirmAllowed = !isMobileSafariWithDialogBlocking();
		this.is360Video = false;
		this.unloadListeners = this.initializeUnloadListeners();
		this.UploadFileService.registerPushHandlers(this.accountId);
	}

	public ngOnDestroy(): void {
		if (!this.persistState) {
			this.UploadFileService.abortVideoUploads();
			this.UploadFileService.resetVideos();
			this.UploadFileService.unregisterPushHandlers();
		}
		this.unloadListeners.forEach(destroyUnloadListener => destroyUnloadListener());
	}

	private initializeUnloadListeners(): Array<() => void> {
		const beforeUnloadEvent: string = 'beforeunload';
		const beforeUnloadHandler = () => this.confirmUnload();
		const removeBeforeUnloadListener = () => window.removeEventListener(beforeUnloadEvent, beforeUnloadHandler);

		window.addEventListener(beforeUnloadEvent, beforeUnloadHandler);

		return [
			this.$transitions.onStart({ exiting: 'portal' }, transition => {
				if (this.isUploading() && this.isConfirmAllowed && !window.confirm(this.TranslateService.instant('Uploads_ConfirmTransition'))) {
					return false;
				}
			}) as () => void,

			this.$transitions.onStart({ exiting: 'portal.media', entering: 'portal.video-basic-settings' }, transition => {
				if (this.isUploading() && this.isConfirmAllowed && this.teamId && !window.confirm(this.TranslateService.instant('Uploads_ConfirmTransition'))) {
					return false;
				}
			}) as () => void,

			removeBeforeUnloadListener
		];
	}

	public addFile({ file }): void {
		const is360: boolean = this.features.enable360VideoUploading && this.is360Video;
		const video = {
			accountId: this.accountId,
			file,
			is360,
			name: file.name,
			progress: 0,
			source: is360 ? VideoSource.UPLOAD_360 : VideoSource.UPLOAD,
			status: { complete: false, error: false, invalidFile: false, exceededMaxFileSize: false, uploading: true },
			title: file.prettyName,
			uploadDate: new Date()
		};

		this.UploadFileService.addVideo(video);

		if (!file.isVideoFile) {
			video.status.invalidFile = true;
			video.status.uploading = false;
			file.abort();
			return;
		}

		if (this.maxFileUploadSizeBytes > 0 && file.size >= this.maxFileUploadSizeBytes) {
			video.status.exceededMaxFileSize = true;
			video.status.uploading = false;
			file.abort();
			return;
		}

		const sessionKeepalive: ISessionKeepalive = this.Session.createKeepalive();
		sessionKeepalive.begin();

		if (this.is360Reset(file)) {
			this.is360Video = false;
		}

		this.addCancelHandler(video);

		this.createVideo(video)
			.then(() => this.uploadVideo(video))
			.then(() => {
				video.status.complete = true;
			})
			.catch(() => {
				video.status.error = true;
			})
			.finally(() => {
				video.status.uploading = false;
				sessionKeepalive.end();
			});
	}

	public clearVideo(video: any): void {
		this.UploadFileService.removeVideo(video);
	}

	public cancelUpload(video: any, $event: BaseJQueryEventObject): void {
		$event.stopPropagation();
		video.cancel();

		this.dismissUpload(video);
	}

	public dismissAllUploads(): void {
		this.UploadFileService.removeCompletedVideoUploads();
	}

	private dismissUpload(video: any): void {
		this.UploadFileService.removeVideo(video);
	}

	private confirmUnload(): string {
		if (this.isUploading()) {
			return this.TranslateService.instant('Uploads_ConfirmUnload');
		}
	}

	private isUploading(): boolean {
		return this.UploadFileService.isAVideoUploading();
	}

	private uploadVideo(video: any): Promise<any> {
		return this.uploadQueue.enqueue(() => {
			if (video.aborted) {
				return;
			}

			video.status.creating = true;

			const uploadPromise = this.UploadService.waitUntilCreated(video.id)
				.then(() => {
					video.status.creating = false;

					if (video.aborted) {
						return this.UploadService.cancelUpload(video.id);
					}

					video.status.submitted = true;
					const file: FileWrapper = video.file;

					return lastValueFrom(file.submit$.pipe(
						tap(e => {
							if (e.isProgress) {
								video.progress = e.loaded / e.total;
								video.bitrate = e.bitrate;
								console.log('progress: ', e);
							}
						})
					));

				});

			return race([uploadPromise, video.cancelPromise]);
		});
	}

	private createVideo(video: any): Promise<any> {
		return this.videoCreateQueue.enqueue(() => {
			if (video.aborted) {
				return;
			}

			return race([
				video.cancelPromise,
				this.UploadService.createVideo(video, this.teamId)
					.then(result => {
						video.id = result.id;
						video.file.setOptions({
							url: result.videoUploadUri
						});
					})]);
		});
	}

	private is360Reset(file: any): boolean {
		if (!file.file) {
			return true;
		}

		const originalFiles = file.file.originalFiles;
		if (!originalFiles || originalFiles.length < 2) {
			return true;
		}

		const lastFile = originalFiles[originalFiles.length - 1];
		return file.name === lastFile.name && file.size === lastFile.size;
	}

	private addCancelHandler(video: any): void {
		video.cancelPromise = new Promise<void>(resolve => {
			video.cancel = () => {
				if (video.id && video.status.submitted) {
					this.UploadService.cancelUpload(video.id);
				}

				video.aborted = true;
				video.file.abort();
				resolve();
			};
		});
	}
}
