import { NgZone } from '@angular/core';
import { partial, throttle } from 'underscore';

import { userAgentUtil } from 'vbrick-player-src/UserAgentUtil';

import { Deferred } from 'rev-shared/util/Deferred';
import { noop } from 'rev-shared/util';

import { IHubProxy } from './IHubProxy';
import { IObservable, Observable } from './Observable';

export interface IAugmentedSignalRHubOptions extends SignalR.Hub.Options {
	autoReconnect: boolean;
}

interface IHubProxyConfig {
	clientMethods: string[];
	serverMethods: string[];
}

interface IHubProxyConfigMap {
	[proxyName: string]: IHubProxyConfig;
}

export enum SignalRHubsConnectionState {
	Connecting = 0,
	Connected = 1,
	Reconnecting = 2,
	Disconnected = 4
}

const HUB_CONNECTION_EVENTS: string[] = [
	// Raised before any data is sent over the connection.
	'starting',
	// Raised when any data is received on the connection. Provides the received data.
	'received',
	// Raised when the client detects a slow or frequently dropping connection.
	'connectionSlow',
	// Raised when the underlying transport begins reconnecting.
	'reconnecting',
	// Raised when the underlying transport has reconnected.
	'reconnected',
	// Raised when the connection state changes. Provides the old state and the new state (Connecting, Connected, Reconnecting, or Disconnected).
	'stateChanged',
	// Raised when the connection has disconnected.
	'disconnected'
];
const SIGNALR_APPLY_THROTTLE_TIME: number = 500;

export class SignalRHubsConnection extends Observable {
	//private connectionObservable: Observable = new Observable();
	private hubConnection: SignalR.Hub.Connection;
	private hubProxies: { [name: string]: IHubProxy };
	private pendingInvoke: Promise<any>;
	private readyDeferred: Deferred;

	public options: SignalR.ConnectionOptions;
	public started: boolean;
	public startupPromise: Promise<any>;
	public zone: NgZone;

	constructor(
		connectionUrl: string, // url + options set in SignalRHubsConfig (duplicated in WebexLive entry point for some reason)
		private connectionOptions: IAugmentedSignalRHubOptions,
		private hubProxyCfg: IHubProxyConfigMap // registerHubProxy() - only in PushHub.Provider
	) {
		super();

		this.hubConnection = $.hubConnection(connectionUrl, connectionOptions);
		this.pendingInvoke = Promise.resolve();
		this.readyDeferred = new Deferred();
		this.started = false;
		this.startupPromise = this.readyDeferred.promise;

		this.init();
	}

	private init(): void {
		this.hubProxies = Object.entries(this.hubProxyCfg)
			.reduce((output, [hubName, hubConfig]) => {
				output[hubName] = this.createHubProxy(hubName, hubConfig.serverMethods, hubConfig.clientMethods);

				return output;
			}, {});

		HUB_CONNECTION_EVENTS.forEach(eventName => {
			this.hubConnection[eventName]((...args: any[]) => {
				this.fire(eventName, ...args);

				this.scopeApply();
			});
		});
	}

	private createHubProxy(hubName: string, serverMethods: string[], clientMethods: string[]): IHubProxy {
		const proxy: SignalR.Hub.Proxy = this.hubConnection.createHubProxy(hubName);

		const invoke = (...args: any[]): Promise<any> => {
			const invocation = this.readyDeferred.promise.then(() => {
				const deferred: Deferred = new Deferred();

				try {
					const [methodName, ...remainingArgs] = args;

					proxy.invoke(methodName, ...remainingArgs)
						.done((result: any) => {
							deferred.resolve(result);
							this.safeApply();
						})
						.fail((error: any) => {
							deferred.reject(error);
							this.safeApply();
						});
				} catch(e) {
					deferred.reject(e);
				}
				return deferred.promise;
			});

			this.addPendingOperation(invocation);

			return invocation;
		};


		const server = serverMethods.reduce((output, currentServerMethod) => {
			output[currentServerMethod] = partial(invoke, currentServerMethod);

			return output;
		}, {});

		const client: IObservable = new Observable();

		const clientImpl = clientMethods.reduce((output, clientMethod) => {
			output[clientMethod] = (...args: any[]) => {
				client.fire(clientMethod, ...args);
				this.safeApply();
			};

			return output;
		}, {});

		clientMethods.forEach(clientMethod => proxy.on(clientMethod, clientImpl[clientMethod]));

		return {
			client,
			server,
			setState(state: any): void {
				Object.entries(state).forEach(([key, value]) => {
					if (value != null) {
						proxy.state[key] = value;
					} else {
						delete proxy.state[key];
					}
				});
			}
		};
	}

	private safeApply(): void { // TODO: replace with alternative. Perhaps fire()
		this.zone?.run(noop);
	}

	private scopeApply: any = throttle(() => this.safeApply(), SIGNALR_APPLY_THROTTLE_TIME);

	public get connectionId(): string {
		return this.hubConnection.id;
	}

	public addPendingOperation(p: Promise<any>): void {
		this.pendingInvoke = Promise.all([this.pendingInvoke, p])
			.catch(noop);
	}

	public bounce(): Promise<any> {
		return this.startupPromise
			.then(() => this.stop(true))
			.then(() => this.start(this.options));
	}

	public getConnectionOptions(): IAugmentedSignalRHubOptions {
		return this.connectionOptions;
	}

	public getConnectionStatus(): SignalRHubsConnectionState {
		return this.hubConnection.state;
	}

	public getProxy(hubName: string): IHubProxy {
		const proxy: IHubProxy = this.hubProxies[hubName];

		if (!proxy) {
			throw new Error(`Hub Proxy was not found: ${hubName}`);
		}

		return proxy;
	}

	/**
	 * Starts the signalR connection. Returns a promise that resolves when the connection is established.
	 * Do not start until all hub proxies have been created, during app startup
	 * options - signalR hubConnection options object.
	 *    additionally:
	 *    reconnectRetryAttempts
	 *    reconnectRetryDelay -  if connection is dropped, attempt to reconnect with a delay between attempts.
	 */
	public start(options: SignalR.ConnectionOptions, reconnect?: boolean): Promise<any> {
		this.options = options;

		const userAgent: string = window.navigator.userAgent;
		const deferred: Deferred = new Deferred();

		// samsung galaxy note native browser - this is not ideal, but updating SignalR breaks the entire solution
		// Mozilla/5.0 (Linux; U; Android 4.2.2; en-us; GT-N5110 Build/JDQ39) AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Safari/534.30

		// TODO: Signalr has been updated. Remove this and test again
		if (userAgent.match(/^Mozilla.*Linux.*U.*Android.*AppleWebKit.*KHTML.*Gecko.*Safari/) &&
			!userAgentUtil.isChromium) {
			options.transport = options.transport || 'longPolling';
		}

		console.log('Signalr', 'starting');
		this.hubConnection.start(options)
			.done(() => {
				console.log('Now connected, connection ID=', this.hubConnection.id);

				this.started = true;
				deferred.resolve();
				this.readyDeferred.resolve();
				this.fire(reconnect ? 'ConnectionReestablished' : 'ConnectionEstablished');
				this.safeApply();
			})
			.fail(error => {
				deferred.reject(error);
				this.readyDeferred.reject(error);
				this.safeApply();
			});

		return deferred.promise;
	}

	public stop(awaitPendingInvokations: boolean): Promise<any> {
		const stopInternal = (): Promise<any> => {
			return new Promise<void>(resolve => {
				this.started = false;

				const onDisconnected = (): void => {
					this.off('disconnected', onDisconnected);
					resolve();
				};

				this.on('disconnected', onDisconnected);

				this.hubConnection.stop(true, true);
			});
		};

		this.readyDeferred = new Deferred();
		this.startupPromise = this.readyDeferred.promise;

		if (awaitPendingInvokations) {
			return this.pendingInvoke.then(stopInternal);
		}

		return stopInternal();
	}
}
