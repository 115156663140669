import { IDisplayStrategy } from './IDisplayStrategy';
import { IRevDisplay, getRevDisplay } from './RevDisplayContext';

export class DistributionDisplayStrategy implements IDisplayStrategy {
	public isEcdn: boolean;

	constructor(isEcdn: boolean) {
		this.isEcdn = isEcdn;
	}

	public getDisplay(): IRevDisplay {
		return getRevDisplay(true, this.isEcdn);
	}
}
