import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { TabsModule } from 'ngx-bootstrap/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { UIRouterModule } from '@uirouter/angular';

import { ButtonsAngularModule } from 'rev-shared/ui/buttons/ButtonsAngular.Module';
import { CheckboxAngularModule } from 'rev-shared/ui/checkbox/CheckboxAngular.Module';
import { DateAngularModule } from 'rev-shared/date/DateAngular.Module';
import { DialogAngularModule } from 'rev-shared/ui/dialog/DialogAngular.Module';
import { DirectivesAngularModule } from 'rev-shared/util/directives/DirectivesAngular.Module';
import { FileUploadAngularModule } from 'rev-shared/ui/fileUpload/FileUpload.AngularModule';
import { FormGroupAngularModule } from 'rev-shared/ui/formGroup/FormGroupAngular.Module';
import { SecurityAngularModule } from 'rev-shared/security/SecurityAngular.Module';
import { SelectAngularModule } from 'rev-shared/ui/select/SelectAngular.Module';
import { SpinnerAngularModule } from 'rev-shared/ui/spinner/SpinnerAngular.Module';
import { TextInputAngularModule } from 'rev-shared/ui/text-input/TextInputAngular.Module';
import { UtilAngularModule } from 'rev-shared/util/UtilAngular.Module';
import { ValidationModule } from 'rev-shared/util/directives/form/validation/Validation.AngularModule';
import { Video360IndicatorModule } from 'rev-shared/media/video360Indicator/Video360Indicator.AngularModule';

import { RecordingAngularModule } from 'rev-portal/media/recording/RecordingAngular.Module';
import { VbrickImportModule } from 'rev-portal/media/import/Import.Module';

import { UploadMenuComponent } from './UploadMenu.Component';
import { UploadFileComponent } from './UploadFile.Component';

const components = [
	UploadMenuComponent,
	UploadFileComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		ButtonsAngularModule,
		CheckboxAngularModule,
		CommonModule,
		DateAngularModule,
		DialogAngularModule,
		DirectivesAngularModule,
		FileUploadAngularModule,
		FormGroupAngularModule,
		FormsModule,
		RecordingAngularModule,
		SecurityAngularModule,
		SelectAngularModule,
		SpinnerAngularModule,
		TabsModule,
		TextInputAngularModule,
		TranslateModule,
		UIRouterModule.forChild(),
		UtilAngularModule,
		ValidationModule,
		VbrickImportModule,
		Video360IndicatorModule,
	]
})

export class UploadMenuModule {}
