<ng-template [vbCssRules]="themeStyleOverrides">
</ng-template>
<!--
	TODO: typeahead-editable not yet implemented
	https://github.com/valor-software/ngx-bootstrap/blob/development/src/typeahead/typeahead.directive.ts#L158
	typeahead-editable="!!typeaheadCfg.getEnteredValueWhenNoSuggestion"
 -->
<form layout="row" layout-wrap="false" name="form" #typeaheadForm="ngForm">
	<div flex="fill" role="search" [ngClass]="styles.inputWrap">
		<input type="text" autocomplete="off" name="editValue" role="searchbox" vbUiTextInput [ngClass]="inputClass" [attr.aria-label]="typeaheadCfg.label" [placeholder]="typeaheadCfg.label" [(ngModel)]="internalValue" [required]="required" #typeahead="bs-typeahead" [typeahead]="typeaheadObservable$" [typeaheadMinLength]="typeaheadMinLength" [typeaheadWaitMs]="TypeaheadDelayMs" [typeaheadIsFirstItemActive]="false" [typeaheadSelectFirstItem]="false" [optionsListTemplate]="customListTemplate" [typeaheadOptionField]="typeaheadCfg.searchField" (typeaheadLoading)="onTypeaheadLoading($event)" (typeaheadNoResults)="onTypeaheadNoResults($event)" (typeaheadOnSelect)="onSelect($event)" (blur)="onInputBlur()" vbKeypress [isKeyUp]="true" (enterKeyPressed)="onEnterKeyPressed()">
		<div [ngClass]="[
				styles.dropdownMenu,
				typeaheadCfg.source === 'HeaderSearch' && typeaheadCfg.headerType === 'TwoTier' ? styles.twoTierDropdownMenu : '',
				typeaheadCfg.searchDropdownCfg?.themedSearchDropdownClass || ''
			]" [hidden]="!(typeaheadLoading || typeaheadNoResults)">
			<vb-loading-spinner [block]="true" [size]="'large'" [ngClass]="styles.fadeInOut" [hidden]="!(typeaheadLoading)">
			</vb-loading-spinner>
			<span [ngClass]="styles.fadeInOut" [hidden]="!(typeaheadNoResults)">
				{{ 'UI_NoResultsFound' | translate }}
			</span>
		</div>
	</div>
	<button *ngIf="isSearchType" class="main-nav-btn" [attr.aria-label]="'Navigation_SearchMedia' | translate " [ngClass]="[styles.noLeftBorderRadius, typeaheadCfg.btnCfg.cssClass]" type="button" (click)="onTypeaheadSubmit()">
		<span class="glyphicons search"></span>
		<span class="mobile-hidden" [hidden]="!typeaheadCfg.btnCfg.label">{{typeaheadCfg.btnCfg.label}}</span>
	</button>
	<button *ngIf="isOkCancelType" class="margin-left-5" [title]="'Apply' | translate " type="button" (click)="onTypeaheadSubmit()" [disabled]="typeaheadForm.invalid">
		<span class="glyphicons ok_2"></span>
	</button>
	<button *ngIf="isOkCancelType" class="margin-left-5" [title]="'Discard' | translate " type="button" (click)="endEdit()" vbUiBtnSecondaryNgx>
		<span class="glyphicons remove_2"></span>
	</button>
</form>

<ng-template #customListTemplate let-matches="matches" let-query="query" let-typeaheadTemplateMethods>
	<ul class="dropdown-menu" [ngClass]="[typeaheadCfg.searchDropdownCfg?.themedSearchDropdownClass || '']">
		<li class="typeahead-list-item" [ngClass]="typeaheadTemplateMethods.isActive(match) ? 'typeahead-match-active' : ''" *ngFor="let match of matches" (mouseenter)="typeaheadTemplateMethods.selectActive(match)">
			<a href="#" [ngClass]="[typeaheadCfg.searchDropdownCfg?.themedSearchDropdownClass || '']" (click)="typeaheadTemplateMethods.selectMatch(match, $event)" tabindex="-1" [attr.title]="match">
				<span [innerHTML]="this.typeahead._container?.highlight(match, query)"></span>
			</a>
		</li>
	</ul>
</ng-template>
