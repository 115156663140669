import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Transition, StateService, ResolvableLiteral } from '@uirouter/angular';

import { Subscription } from 'rxjs';

import { LoginRedirectService } from '../LoginRedirect.Service';
import { UserContextService } from '../UserContext.Service';
import { SecurityContextService } from '../SecurityContext.Service';

import authLayoutStyles from 'rev-shared/ui/authLayout/VbAuthLayout.Module.less';

export const LoginResolve = {
	fwdUrl($transition$: Transition): string {
		'ngInject';

		return $transition$.params().fwdUrl;
	},

	initiateLogin($transition$: Transition): string {
		'ngInject';

		return $transition$.params().initiateLogin;
	},

	invalidCredentials($transition$: Transition): boolean {
		'ngInject';

		return $transition$.params().invalidCredentials;
	},

	sessionEnded($transition$: Transition): boolean {
		'ngInject';

		return $transition$.params().sessionEnded;
	}
};

export const LoginResolveNg2: ResolvableLiteral[] = [
	{
		token: 'fwdUrl',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().fwdUrl
	},
	{
		token: 'initiateLogin',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().initiateLogin
	},
	{
		token: 'invalidCredentials',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().invalidCredentials
	},
	{
		token: 'sessionEnded',
		deps: [Transition],
		resolveFn: (transition: Transition) => transition.params().sessionEnded
	}
];

interface ILoginStatus {
	active: boolean;
	loading: boolean;
	processing: boolean;
	statusMessage: string;
}

@Component({
	selector: 'login',
	templateUrl: './Login.Component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
	@Input() public disablePasswordReset?: boolean;
	@Input() public fwdUrl: string;
	@Input() public initiateLogin: boolean;
	@Input() public invalidCredentials: boolean;
	@Input() public sessionEnded: boolean;
	@Input('loginStyles') public styles: any;
	@Input() public thumbnailUri?: string;
	@Input() public onLogoutSuccess: () => void;

	@ViewChild(NgForm) protected loginForm: NgForm;

	public readonly authLayoutStyles = authLayoutStyles;

	public formData: { username: string; password: string };
	public status: ILoginStatus;

	private loadedLanguage: string;
	private subscription: Subscription;

	constructor(
		protected $state: StateService,
		protected LoginRedirectService: LoginRedirectService,
		protected SecurityContext: SecurityContextService,
		protected UserContext: UserContextService,
		protected TranslateService: TranslateService
	) {}

	public ngOnInit(): void {
		this.loadedLanguage = this.UserContext.getCurrentLanguage();

		if (!this.doLogOut()) {
			this.resetStatus();
			this.status.active = true;
			this.status.statusMessage = this.invalidCredentials ? this.TranslateService.instant('Login_InvalidCredentials') : '';
		}

		this.formData = {
			username: !this.UserContext.isRegisteredGuest() ? this.UserContext.getUser().username : null, // don't show the id generated for a registered guest
			password: null
		};

		this.subscription = this.UserContext.userIdChanged$.subscribe(() => {
			if(this.UserContext.isUserAuthenticated()) {
				this.onUserAuthenticated();
			}
		});
	}

	public ngOnDestroy(): void {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	protected onUserAuthenticated() {
		this.redirectToLandingUrl();
	}

	public submit(): void {
		if (!this.loginForm.valid) {
			this.status.statusMessage = this.TranslateService.instant('Login_NoCredentialsMessage');
			return;
		}

		this.resetStatus();
		this.status.processing = true;

		this.UserContext.authenticateUser(this.formData.username, this.formData.password)
			.catch((result: any) => {
				this.resetStatus();
				this.status.active = true;
				this.onLoginError(result);
			});

		this.formData.password = null;
	}

	/**
	 * If User visits the login form after being logged in - automatically log them out
	 */
	protected doLogOut(): boolean {
		if(!this.UserContext.isUserLoggedIn()) {
			return false;
		}

		this.resetStatus();
		this.status.loading = true;

		this.UserContext.logOutUser(this.sessionEnded || this.UserContext.isRegisteredGuest())
			.then(() => this.onLogoutSuccess?.())
			.catch(e => console.log('doLogout error: ', e))
			.finally(() => {
				this.resetStatus();
				this.status.active = true;

				if (this.sessionEnded) {
					this.status.statusMessage = this.TranslateService.instant('Login_SessionEndedMessage');
				} else {
					this.status.statusMessage = this.TranslateService.instant('Login_LoggedOutMessage');
				}
			});

		return true;
	}

	public onLoginError(result: any): void {
		if (result === 'LogOnFailed') {
			this.status.statusMessage = this.TranslateService.instant('Login_BadCredentialsMessage');
		} else if (result === 'LockedOut') {
			this.status.statusMessage = this.TranslateService.instant('Login_LockedOutMessage');
		} else if (result === 'NotActive') {
			this.status.statusMessage = this.TranslateService.instant('Login_SuspendedMessage');
		} else if (result === 'LogOnFailedMaintenance') {
			this.status.statusMessage = this.TranslateService.instant('Login_MaintenanceMessage');
		} else if (result === 'InvalidLicense') {
			this.status.statusMessage = this.TranslateService.instant('License_Error_UserShort');
		} else if (result.userAgreementRequired) {
			this.$state.go('user-agreement', {
				fwdUrl: this.fwdUrl,
				userId: result.userId
			});
		} else {
			this.status.statusMessage = this.TranslateService.instant('Login_ErrorMessage');
		}
	}

	private resetStatus(): void {
		this.status = {
			active: false,
			loading: false,
			processing: false,
			statusMessage: '',
		};
	}

	public redirectToLandingUrl(forceRefresh?: boolean): void {
		const nextLanguage = this.UserContext.getUser().resolvedLanguage;
		const refreshPage = forceRefresh ||
			nextLanguage && this.loadedLanguage !== nextLanguage;

		this.LoginRedirectService.redirectToLandingPage(this.fwdUrl, refreshPage);
	}

	public allowPasswordReset(): boolean {
		return !this.disablePasswordReset && this.SecurityContext.allowStateChangeSync('forgot-password');
	}
}
