import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CarouselType } from 'rev-portal/branding/BrandingSettings.Contract';
import { MEDIA_STATE_ALL, MEDIA_STATE_BROWSE } from 'rev-portal/media/MediaStateDeclarations';
import { VIDEO_PLAYBACK_STATE_NAME } from 'rev-portal/media/videos/videoPlayback/Constants';
import { WEBCAST_FULLSCREEN_STATE, WEBCAST_VIEW_STATE } from 'rev-portal/scheduledEvents/webcast/Constants';

import { PushBus } from 'rev-shared/push/PushBus.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { BrandingService } from './Branding.Service';
import { BrandingSettings } from './BrandingSettings.Contract';
import { setOpacity } from './ColorUtility';

export const THEMED_STATES = [
	'portal.media.category-detail',
	'portal.media.edit',
	'portal.media.expirations',
	'portal.media.my-subscriptions',
	'portal.media.search',
	'portal.media.uploads',
	'portal.media.pending-videos',
	'portal.userProfile',
	'portal.media.my-playlists',
	'portal.media.playlists-featured-playlist',
	'portal.media.playlist-detail',
	MEDIA_STATE_ALL,
	MEDIA_STATE_BROWSE,
	WEBCAST_FULLSCREEN_STATE,
	WEBCAST_VIEW_STATE,
	VIDEO_PLAYBACK_STATE_NAME
];

@Injectable({
	providedIn: 'root'
})
export class ThemeService {

	public isPreviewActive: boolean;
	public isCustomThemeActive: boolean;
	public initialized: boolean;
	public initializationPromise: Promise<void>;

	public get brandingSettings(): BrandingSettings {
		return this.brandingSettingsSubject$.value;
	}

	private baseSettings: BrandingSettings = new BrandingSettings();
	private overrideSettings: BrandingSettings;
	public readonly brandingSettingsSubject$ = new BehaviorSubject<BrandingSettings>(this.baseSettings);
	public readonly brandingSettings$: Observable<BrandingSettings> = this.brandingSettingsSubject$.asObservable();

	public get accentFontColor(): string {
		return this.brandingSettings.themeSettings.accentFontColor;
	}

	public get accentColor(): string {
		return this.brandingSettings.themeSettings.accentColor;
	}

	public get headerFontColor(): string {
		return this.brandingSettings.headerSettings.fontColor;
	}

	public get headerBackgroundColor(): string {
		return this.brandingSettings.headerSettings.backgroundColor;
	}

	public get primaryColor(): string {
		return this.brandingSettings.themeSettings.primaryColor;
	}

	public get primaryFontColor(): string {
		return this.brandingSettings.themeSettings.primaryFontColor;
	}

	public get primaryFontFade30(): string {
		return setOpacity(this.primaryFontColor, .3);
	}

	constructor(
		private BrandingService: BrandingService,
		private UserContext: UserContextService,
		private PushBus: PushBus
	){
		UserContext.userIdChanged$.pipe(
			filter(() => !UserContext.isUserLoggedIn())
		)
			.subscribe(() => this.endCustomThemeMode());
	}

	public initialize(): Promise<void> {
		if(this.initializationPromise) {
			return this.initializationPromise;
		}
		return this.initializationPromise = this.loadSettings()
			.then(() => {
				this.initialized = true;
				return this.subscribePush();
			});
	}

	public loadSettings(): Promise<void> {
		return this.BrandingService.getAppBranding()
			.then(brandingSettings => {
				this.baseSettings = brandingSettings;
				this.updateSettings();
			});
	}

	public setPreviewMode(brandingSettings: BrandingSettings): void {
		this.isPreviewActive = true;
		this.overrideSettings = brandingSettings;
		this.updateSettings();
	}

	public endPreviewMode(): BrandingSettings {
		return this.endCustomThemeMode();
	}

	public setCustomThemeMode(brandingSettings: BrandingSettings): void {
		this.overrideSettings = brandingSettings;
		this.isCustomThemeActive = true;
		this.updateSettings();
	}

	public endCustomThemeMode(): BrandingSettings {
		const previewTheme = this.overrideSettings;
		this.overrideSettings = null;
		this.isCustomThemeActive = false;
		this.isPreviewActive = false;
		this.updateSettings();
		return previewTheme;
	}

	private getOverrideSettings(): BrandingSettings {
		if (this.isCustomThemeActive) {
			const baseSettings = this.baseSettings;
			const brandingSettings = this.overrideSettings;
			return {
				...baseSettings,
				themeSettings: brandingSettings.themeSettings ? {
					accentColor: brandingSettings.themeSettings.accentColor || baseSettings.themeSettings.accentColor,
					accentFontColor: brandingSettings.themeSettings.accentFontColor || baseSettings.themeSettings.accentFontColor,
					primaryColor: brandingSettings.themeSettings.primaryColor || baseSettings.themeSettings.primaryColor,
					primaryFontColor: brandingSettings.themeSettings.primaryFontColor || baseSettings.themeSettings.primaryFontColor,
					fontId: brandingSettings.themeSettings.fontId || baseSettings.themeSettings.fontId,
					fontUri: brandingSettings.themeSettings.fontUri || baseSettings.themeSettings.fontUri,
					logoFile: brandingSettings.themeSettings.logoFile || baseSettings.themeSettings.logoFile,
					logoImageId: brandingSettings.themeSettings.logoImageId || baseSettings.themeSettings.logoImageId,
					logoUri: brandingSettings.themeSettings.logoUri || baseSettings.themeSettings.logoUri
				} : baseSettings.themeSettings,
				headerSettings: brandingSettings.headerSettings ? {
					...baseSettings.headerSettings,
					backgroundColor: brandingSettings.headerSettings.backgroundColor || baseSettings.headerSettings.backgroundColor,
					fontColor: brandingSettings.headerSettings.fontColor || baseSettings.headerSettings.fontColor
				} : baseSettings.headerSettings
			};
		}
		if(this.isPreviewActive) {
			return this.overrideSettings;
		}

		return this.baseSettings;
	}

	private subscribePush(): void {
		this.PushBus.subscribe(this.UserContext.getAccount().id, {
			BrandingSettingsSaved: e => {
				if (e.isTeamVideosCarouselAvailable) {
					this.loadSettings();
				}
				else {
					this.baseSettings = this.BrandingService.readBrandingSettings(e.settings);
					this.updateSettings();
				}
			},
			CarouselsRemoved: e => {
				e.carousels.forEach((removedCarousel: CarouselType) => {
					this.brandingSettings.homepageSettings.carousels.splice(this.brandingSettings.homepageSettings.carousels.findIndex(c => c.type === removedCarousel), 1);
				});
				this.brandingSettingsSubject$.next(this.brandingSettings);
			}
		});
	}

	private updateSettings(): void {
		this.brandingSettingsSubject$.next(this.getOverrideSettings());
	}
}
