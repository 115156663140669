import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { isNumber, isString } from 'rev-shared/util';

import { DayMs, HourMs, MinuteMs } from './Time.Constant';

/**
 * options: {
 * allowNegative: returns null if timespan < 0
 * noDays:  don't include date field, hours will grow above 24
 * noHours:  don't include hours for times less than 1 hour,
 * padFields: pad hours/minutes/seconds to 2 digits (ex - 05:00:00),
 * noSeconds: do not include seconds or milliseconds
 * noMilliseconds: do not include milliseconds,
 * includeMilliseconds: always include milliseconds, even if zero
 * decimalSeparator: default is '.'
 * }
 */
export interface IFormatTimespanOptions {
	allowNegative?: boolean;
	noDays?: boolean;
	noHours?: boolean;
	noMilliseconds?: boolean;
	includeMilliseconds?: boolean;
	noSeconds?: boolean;
	padFields?: boolean;
	padHours?: boolean; //Override padFields for hours
	decimalSeparator?: string;
}

export function formatIsoDate(date: Date): string {
	return date && formatDate(date, 'yyyy-MM-dd', 'en-US'); // no localization needed, so hard-coded the locale to the default
}

export function formatMediumDateTime(date: Date): string {
	return date && moment(date).format('lll');
}

export function formatMediumDate(date: Date): string {
	return date && moment(date).format('ll');
}

export function formatShortTime(date: Date): string {
	return date && moment(date).format('LT');
}

/**
 * formats timespan as d.hh:mm:ss.ffff
 * @param options IFormatTimespanOptions
 */
export function formatTimespan(timespan: number, opts?: IFormatTimespanOptions): string {
	const pad2IfOpt = (value: number | string): number | string => {
		return opts.padFields ? pad(value, 2) : value;
	};
	const timeInfo = getTimeInfo(timespan);
	opts = opts || {};

	if (!timeInfo || (timeInfo.negative && !opts.allowNegative)) {
		return;
	}
	const output = [];

	if (timeInfo.negative) {
		output.push('-');
	}

	const includeDays: boolean = timeInfo.asDays && !opts.noDays;

	if(includeDays) {
		output.push(timeInfo.asDays, '.');
	}

	if(timeInfo.asHours || !opts.noHours) {
		const hh = includeDays ? timeInfo.hours : timeInfo.asHours;
		const padHours = opts.padHours !== undefined ? opts.padHours : opts.padFields;

		output.push(padHours ? pad(hh, 2) : hh, ':');
	}

	output.push(pad2IfOpt(timeInfo.minutes));

	if(!opts.noSeconds) {
		output.push(':', pad2IfOpt(timeInfo.seconds));
		if(opts.includeMilliseconds || timeInfo.milliseconds && !opts.noMilliseconds) {
			output.push(opts.decimalSeparator || '.', pad(timeInfo.milliseconds, 3));
		}
	}

	return output.join('');
}

/**
 * Formats milliseconds into a h:mm:ss string. Where hours may be omitted if 0 or always included.
 * @param timespan Value in milliseconds.
 * @param includeHours whether or not to include hours in the output. If false, hours is only omitted when it is a 0-value. If includeSeconds is false, this param will be ignored and hours will always be included.
 * @param includeSeconds whether or not to include seconds in the output.
 */
export function formatTimespanShort(timespan: number | string, includeHours = false, includeSeconds = true): string {
	const timespanNum: number = isString(timespan) ?
		+timespan :
		timespan;

	if (isNaN(timespanNum) || !isNumber(timespanNum) || timespanNum < 0) {
		return '';
	}

	const formattedTimeSpan: string = formatTimespan(timespanNum, {
		noDays: true,
		noHours: !includeHours && includeSeconds,
		padFields: true,
		padHours: false,
		noSeconds: !includeSeconds,
		noMilliseconds: true
	});

	return formattedTimeSpan;
}

/**
 * Given a time value in ms like a playback position, returns a string formatted for URL parameter friendliness
 * in the format ##h##m##s or ##m##s.
 * @param  {Number} timespan value in ms.
 * @return {String}          ##h##m##s or ##m##s.
 */
export function formatUrlTimespan(timespan: number, includeMillis: boolean = false): string {
	if (!isNumber(timespan)) {
		return;
	}

	const time = getTimeInfo(timespan);

	if (!time || time.negative) {
		return;
	}

	const output = [];

	if(time.asHours) {
		output.push(time.asHours, 'h');
	}

	output.push(pad(time.minutes, 2), 'm', pad(time.seconds, 2));

	if (time.milliseconds && includeMillis) {
		output.push('.', pad(time.milliseconds, 3));
	}

	output.push('s');

	return output.join('');
}

/**
 * This method returns length of duration in Days, Hours, Minutes, Seconds, Milliseconds
 * @param ms <-- time in millisecond
 */
function getTimeInfo(ms: number) {
	ms = Math.floor(+ms);
	if (isNaN(ms) || !isNumber(ms)) {
		return;
	}

	let neg = false;
	if (ms < 0) {
		neg = true;
		ms *= -1;
	}

	const duration: moment.Duration = moment.duration(ms);

	return {
		asDays: Math.floor(duration.asDays()),
		asHours: Math.floor(duration.asHours()),
		hours: duration.hours(),
		minutes: duration.minutes(),
		seconds: duration.seconds(),
		milliseconds: duration.milliseconds(),
		negative: neg
	};
}

function pad(input: number | string, targetLength: number): string {
	return input.toString().padStart(targetLength, '0');
}

export function formatAbbreviatedTimespan(translate: TranslateService, timeMs: number): string {
	if(!(timeMs >= 0)) {
		return '';
	}

	const duration = moment.duration(timeMs);

	let unit: number;
	let formatString: string;
	if(timeMs >= DayMs) {
		unit = Math.floor(duration.asDays());
		formatString = unit === 1 ? 'Time_Day' : 'Time_Days';
	}
	else if(timeMs >= HourMs) {
		unit = Math.floor(duration.asHours());
		formatString = unit === 1 ? 'Time_Hour' : 'Time_Hours';
	}
	else if(timeMs >= MinuteMs) {
		unit = Math.floor(duration.asMinutes());
		formatString = unit === 1 ? 'Time_Minute' : 'Time_Minutes';
	}
	else {
		unit = Math.floor(duration.asSeconds());
		formatString = unit === 1 ? 'Time_Second' : 'Time_Seconds';
	}

	return translate.instant(formatString, { 0: unit });
}
