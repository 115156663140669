<div [ngClass]="styles.cell">
	<vb-profile-picture [profileUrl]="item[insight.insightOptions.fieldDefs.profileImageUri]" [placeholderIconClass]="insight.getIconClass(item)" [altTxt]="item[insight.insightOptions.fieldDefs.display]"></vb-profile-picture>
</div>

<div [ngClass]="styles.descriptionCell">
	<span>{{ item[insight.insightOptions.fieldDefs.display] }}</span>
	<span *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]" [ngClass]="styles.subDisplay">{{ item[insight.insightOptions.fieldDefs.subDisplay] }}</span>
</div>

<div [ngClass]="styles.cell">
	<button type="button" class="btn btn-edit-access" [ngClass]="styles.btnBadge" [(ngModel)]="item.suppressEmail" btnCheckbox [btnCheckboxFalse]="false" [btnCheckboxTrue]="true">
		<span [ngClass]="item.suppressEmail ? 'ok_2' : 'minus'" class="glyphicons"></span>
		{{ 'Admin_MediaSettings_ApprovalProcesses_SuppressEmail' | translate }}
	</button>
</div>

<div>
	<button type="button" class="btn btn-link pull-right remove-assigned-item" (click)="insight.removeItem(item)" [attr.aria-label]="'RemoveItemType' | translate : {
			'0': item[insight.insightOptions.fieldDefs.display],
			'1': (insight.insightOptions.dataTypeLabels[item.type] | translate)
		}">
		<span class="glyphicons remove_2"></span>
	</button>
</div>
