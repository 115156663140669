import { HttpClient } from '@angular/common/http';
import { modifyUrlQueryParams } from 'rev-shared/util/Util.Service';
import { from, mergeMap, EMPTY, of, Observable, throwError } from 'rxjs';
import { first, catchError, map, tap, filter, timeout } from 'rxjs/operators';
import { IPlaybackCacheNode } from './IPlaybackCacheNode';

const OMNICACHE = 'omnicache';

export function getOmniCachePlaybackUrl(sourceUrl: string, OmniCacheNodeUrl: string): string {
	const queryParamsStr = sourceUrl.split('?')?.[1];
	const replaceCnodeParam = new URLSearchParams(queryParamsStr)?.get('cnode')?.toLowerCase() === OMNICACHE;

	return OmniCacheNodeUrl && replaceCnodeParam ?
		modifyUrlQueryParams(sourceUrl, params => {
			params.cnode = OmniCacheNodeUrl.replace(/^https?:\/\//, '');
			return params;
		})
		: OmniCacheNodeUrl ? `${OmniCacheNodeUrl}/https/${sourceUrl.replace(/^https?:\/\//, '')}`
		: sourceUrl;
}
export function getPlaybackOmniCacheNode(http: HttpClient, cacheNodes: IPlaybackCacheNode[]): Observable<IPlaybackCacheNode> {
	return getFastestOmniCacheNode(http, cacheNodes.map(node => node.url))
		.pipe(
			map(response => {
				return cacheNodes.find(node => node.url.includes(response.url));
			}),
			catchError(err => throwError(() => new Error(err)))
		);
}

function getFastestOmniCacheNode(http: HttpClient, cacheUrls: string[]): Observable<any> {
	let failureCount = 0;

	const omniCaCheNode$ = url => {
		return http.get(url)
			.pipe(
				timeout(6000),
				map(result => ({ ...result, url })),
				catchError(err => {
					console.error('error while trying to reach OmniCache Node', err);
					return EMPTY;
				})
			);
	};

	if (!cacheUrls?.length) {
		console.error('No Omnicache Nodes');
		return of(undefined);
	}

	return from(cacheUrls)
		.pipe(
			mergeMap(url => omniCaCheNode$(url)),
			catchError(err => {
				failureCount = ++failureCount;
				if (cacheUrls.length === failureCount) {
					console.error('Not able to reach a single OmniCache', err);
					return throwError(() => new Error(err));
				}
				return EMPTY;
			}),
			first(val => !!val)
		);
}
