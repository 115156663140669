import { enableProdMode, NgModuleRef, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UrlService, UIRouter } from '@uirouter/angular';
import { setTheme, BsVerions } from 'ngx-bootstrap/utils';


import { BootstrapContext } from 'rev-shared/bootstrap/BootstrapContext';
import { initLocale } from './LocaleContext';
import { monkeyPatchHttpRequest } from './HttpRequestUtil';

export function bootstrapAngularApp(angularRootModule): Promise<any> {
	if (process.env.NODE_ENV === 'production' && !BootstrapContext.debug) { // provided by webpack
		enableProdMode();
	}

	monkeyPatchHttpRequest();

	initLocale();
	initNgxBootstrap();

	return platformBrowserDynamic()
		.bootstrapModule(angularRootModule)
		.then(startAngularUiRouter);
}

function startAngularUiRouter(platformRef: NgModuleRef<any>): NgModuleRef<any> {
	const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
	const ngZone: NgZone = platformRef.injector.get(NgZone);

	ngZone.run(() =>{
		urlService.listen();
		urlService.sync();
	});

	return platformRef;
}

function initNgxBootstrap(): void {
	setTheme?.(BsVerions.isBs3);
}
