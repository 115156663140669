import {
	Component,
	Input
} from '@angular/core';

import { isMobile } from 'rev-shared/util/UserAgentUtil';

import styles from './vb-ui-dropdown-menu.module.less';

export enum VbUIDropdownMenuAlign {
	RIGHT = 'right'
}

@Component({
	selector: 'vb-dropdown-menu',
	template: `
		<div [ngClass]="styles.dropDown"
			(mouseenter)="!isMobile && onMenuDisplayState(true)"
			(mouseleave)="onMenuDisplayState(false)">
			<button type="button"
				[ngClass]="styles.dropDownButton"
				(click)="isMobile && onMenuDisplayState(true)"
				[attr.aria-label]="accessibilityTitle || buttonTitle"
				vbUiBtnPrimary>
				<span class="glyphicons" [ngClass]="icon"></span>
				<span class="xs-hide">
					{{ buttonTitle }}
				</span>
			</button>
			<div
				[ngClass]="[
					styles.dropDownContent,
					cssClass ? cssClass : '',
					alignMenu === VbUIDropdownMenuAlign.RIGHT ? styles.alignMenuRight : ''
				]"
				[hidden]="!isOpen">
				<div [ngClass]="styles.listWrap">
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	`
})
export class VbDropdownMenuComponent {
	@Input() public accessibilityTitle: string;
	@Input() public alignMenu: VbUIDropdownMenuAlign;
	@Input() public buttonTitle: string;
	@Input() public cssClass: string;
	@Input() public icon: string;

	public readonly styles = styles;
	public readonly VbUIDropdownMenuAlign = VbUIDropdownMenuAlign;

	public readonly isMobile: boolean = isMobile();
	public isOpen: boolean;

	public onMenuDisplayState(isOpen: boolean): void {
		this.isOpen = isOpen;
	}
}
