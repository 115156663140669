import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { range } from 'underscore';

import { IVideoOverlay } from '@vbrick/vbrick-player/app/player/videoOverlay/IVideoOverlay';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { FileUtil } from 'rev-shared/util/FileUtil';
import { IChapter } from 'rev-shared/media/videoChapter/IChapter';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PushService, ICommandPromise } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { VideoChaptersModel } from 'rev-shared/media/videoChapter/VideoChaptersModel';
import { ViewerIdPolicy } from 'rev-shared/viewerId/ViewerIdContract';
import { constructViewerIdOverlay } from 'rev-shared/videoPlayer/VideoOverlayUtility';
import { isArray } from 'rev-shared/util';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';

import { IVideoBasicInfo } from './Media.Contract';
import { IMediaFeatures } from './IMediaFeatures';

@Injectable({
	providedIn: 'root'
})
export class VideoService {
	constructor(
		private DateParsers: DateParsersService,
		private PushService: PushService,
		private UserContext: UserContextService,
		private UserLocalIPService: UserLocalIPService,
		private http: HttpClient,
		private MediaFeaturesService: MediaFeaturesService
	) {}

	public approveVideo(video: any, reason: any): ICommandPromise<void> {
		return this.PushService
			.dispatchCommand('media:ApproveVideo', {
				videoId: video.id,
				reason
			});
	}

	public cancelSupplementalContentUpload(content: { videoId: string; id: string}): ICommandPromise<void> {
		return this.PushService
			.dispatchCommand('media:CancelUploadingMediaContent', {
				videoId: content.videoId,
				mediaContentId: content.id
			});
	}

	public clearTranscodeError(videoId: string): ICommandPromise<void>{
		return this.PushService.dispatchCommand('media:ClearTranscodeError', { videoId }, 'TranscodeErrorCleared');
	}

	public fetchPresentationProfiles(accountId: string): Promise<any> {
		return lastValueFrom(this.http.get(`/media/accounts/${accountId}/presentation-profiles`));
	}

	public getAssignableTeams(): Promise<any> {// TODO: validate. Used query() previous which assumes array return value
		return lastValueFrom(this.http.get(`/network/teams/assignable`));
	}

	public getViewableTeams(): Promise<any> {// TODO: validate. Used query() previous which assumes array return value
		return lastValueFrom(this.http.get(`/network/teams/viewable`));
	}

	public getThumbnailCfg(thumbCfg: any, thumbnailSheetsCreating = false) {
		thumbCfg = thumbCfg || {};
		return {
			ready: thumbCfg.numSheets > 0,
			thumbnailSheetsCreating,
			isProcessing: thumbnailSheetsCreating,

			pageResolution: {
				width: thumbCfg.sheetWidth,
				height: thumbCfg.sheetHeight
			},
			thumbnailTiling: {
				horizontal: thumbCfg.horizontalTiles,
				vertical: thumbCfg.verticalTiles
			},
			tileResolution: {
				width: thumbCfg.sheetWidth / thumbCfg.horizontalTiles,
				height: thumbCfg.sheetHeight / thumbCfg.verticalTiles
			},
			pageUrls: range(thumbCfg.numSheets || 0).map(i => `${thumbCfg.thumbnailSheetsUri}/${i + 1}`),
			thumbnailDuration: thumbCfg.spf * 1000
		};
	}

	public getUserUploadedVideosCount(): Promise<number> {
		return lastValueFrom(this.http.get<{ count: number }>(`/media/videos/user-upload-count`))
			.then(result => result.count);
	}

	public getVideoBasicInfo(videoId: string): Promise<IVideoBasicInfo> {
		return lastValueFrom(this.http.get<IVideoBasicInfo>(`/media/videos/${videoId}/basic-info`))
			.then(video => ({
				id: videoId,
				...video
			}));
	}

	public getVideoPlayback(videoId: string, mediaFeatures?: IMediaFeatures): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/media/videos/${videoId}`))
			.then(response => {
				const video = response.video;

				video.videoUserRating = response.videoUserRating;

				this.generateSupplementalContentExtensions(response.mediaContent);

				Object.assign(video, {
					whenUploaded: this.DateParsers.parseUTCDate(video.whenUploaded),
					duration: this.DateParsers.parseTimespan(video.duration),
					comments: video.comments || [],
					customFieldValues: video.customFieldValues || [],
					heartbeatInterval: response.heartbeatInterval,
					transcodeOnDemandInitiator: response.transcodeOnDemandInitiator
				});

				video.comments.forEach(comment => {
					Object.assign(comment, {
						videoId: video.id,
						date: this.DateParsers.parseUTCDate(comment.date),
						showReplies: true
					});

					(comment.childComments || []).forEach(childComment => Object.assign(childComment, {
						parentCommentId: comment.id,
						videoId: video.id,
						date: this.DateParsers.parseUTCDate(childComment.date)
					}));
				});

				video.chapterInfo = this.shapeChapterInfoModel(video.chapters);
				delete video.chapters;

				//if unauthenticated, then don't return mediaContent
				if (!this.UserContext.isUserAuthenticated()) {
					response.mediaContent = [];
				}

				video.subtitles = (response.transcriptionFiles || []).map(transcriptionFile => this.toPlayerDirectiveSubtitle(transcriptionFile));

				// Do not change Akamai urls
				if (isArray(video.downloadUrls) && video.downloadUrls.length) {
					video.downloadUrl = video.downloadUrls[0].includes('/videos/') ?
						video.downloadUrls[0].replace('instances', 'instances-file') :
						video.downloadUrl = video.downloadUrls[0];
				}

				video.thumbnailCfg = this.getThumbnailCfg(video.thumbnailCfg, video.thumbnailSheetsCreating);
				video.videoOverlays = mediaFeatures ? this.getViewerIdOverlays(mediaFeatures, video.viewerIdEnabled, this.MediaFeaturesService.accountFeatures.viewerIdSettings.viewerIdPolicy === ViewerIdPolicy.ALLOW ? video.viewerIdWatermarkText : '') : undefined;

				return response;
			});
	}

	public getVideoPlaybackEmbed(videoId: string, password?: string): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/media/videos/${videoId}/embed`, password ? {
			headers: { [`${videoId}-auth`]: password }
		} : undefined ))
			.then(video => {
				return this.MediaFeaturesService.getFeatures()
					.then(features => ({ video, features }));
			})
			.then( (response: any) => {
				const video = response.video;
				const mediaFeatures = response.features;
				return Object.assign(video, {
					chapterInfo: this.shapeChapterInfoModel(video.chapters),
					chapters: undefined,
					duration: this.DateParsers.parseTimespan(video.duration),
					subtitles: (video.transcriptionFiles || []).map(transcriptionFile => this.toPlayerDirectiveSubtitle(transcriptionFile)),
					thumbnailCfg: this.getThumbnailCfg(video.thumbnailCfg),
					videoOverlays: mediaFeatures ? this.getViewerIdOverlays(mediaFeatures, video.viewerIdEnabled, video.viewerIdEnabled ? video.viewerIdWatermarkText : '') : undefined
				});
			});
	}

	public lockVideos(accountId: string, videoIds: string[]): Promise<any> {
		return this.PushService.dispatchCommand('media:LockVideos', { accountId, videoIds }, 'VideosLockedCompleted')
			.then(result => result.message.videoIds);
	}

	public rateVideo(videoRating: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:RateVideo', videoRating);
	}

	public rejectVideo(video: any, reason: any): ICommandPromise<void> {
		return this.PushService
			.dispatchCommand('media:RejectVideo', {
				videoId: video.id,
				reason
			});
	}

	public replaceVideo(videoId: string, fileName: string, size: number) {
		return this.PushService.dispatchCommand('media:ReplaceVideo', { videoId, fileName, size }, 'VideoReplacing')
			.then(result => result.message.uploadUri);
	}

	public reportVideo(video: any, reason: any): ICommandPromise<void> {
		return this.PushService
			.dispatchCommand('media:ReportVideo', {
				videoId: video.id,
				reason
			});
	}

	public revertVideo(videoId){
		return this.PushService.dispatchCommand('media:RevertVideo', { videoId });
	}

	public submitForApproval(video: { videoId: string; templateId: string; ownerUserId: string }): ICommandPromise<void> {
		return this.PushService
			.dispatchCommand('media:SubmitVideoForApproval', {
				videoId: video.videoId,
				processTemplateId: video.templateId,
				ownerUserId: video.ownerUserId
			});
	}

	public toPlayerDirectiveSubtitle(transcriptionFile: { downloadUrl: string; languageId: string }) {
		return {
			src: transcriptionFile.downloadUrl,
			languageCode: transcriptionFile.languageId
		};
	}

	public transcodeVideo(videoId: string, initiator: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:TranscodeVideoOnDemand', { videoId, initiator }, 'VideoTranscodingOnDemand');
	}

	public unlockVideos(accountId: string, videoIds: string[]): Promise<string[]> {
		return this.PushService.dispatchCommand('media:UnlockVideos', { accountId, videoIds }, 'VideosUnlockedCompleted')
			.then(result => result.message.videoIds);
	}

	public getVideoSession(videoId: string): Promise<any> {
		const user = this.UserContext.getUser();

		if (!this.UserContext.isUserAuthenticated()) {
			return Promise.resolve();
		}

		return lastValueFrom(this.http.get<any>(`/media/users/${user.id}/videos/${videoId}/session`))
			.then(session => ({
				...session,
				time: this.DateParsers.parseTimespan(session?.time)
			}));
	}

	public getRecentlyViewedVideos(): Promise<any> {
		const user = this.UserContext.getUser();

		if (!this.UserContext.isUserAuthenticated()) {
			return Promise.resolve();
		}

		return lastValueFrom(this.http.get<{ videos: any[] }>(`/media/users/${user.id}/recent-views`))
			.then(result => result.videos.map(item => ({
				...item.video,
				duration: this.DateParsers.parseTimespan(item.video.duration),
				session: {
					...item.session,
					time: this.DateParsers.parseTimespan(item.session.time)
				}
			})));
	}

	private generateSupplementalContentExtensions(supplementalContent: Array<{ filename: string; extension: string; isImageFile: boolean }>): void {
		if (supplementalContent) {
			supplementalContent.forEach(content => {
				const parsed = FileUtil.parseFileName(content.filename);

				if (parsed) {
					const extension: string = content.extension = parsed.extension;
					content.isImageFile = FileUtil.isImageFile(extension);
				}
			});
		}
	}

	public shapeChapters(chapters: any, chapterInfoUri: string, chapterInfoThumbnailUri): IChapter[] {
		chapters.forEach(chapter => {
			chapter.time = this.DateParsers.parseTimespan(chapter.time);

			if(chapter.imageId) {
				const fileName = chapter.imageId + '.' + chapter.extension;
				Object.assign(chapter, {
					url: chapterInfoUri + fileName,
					thumbnailUrl: chapter.isUploadedImage ? null : chapterInfoThumbnailUri + fileName
				});
			}
		});

		return chapters;
	}

	private shapeChapterInfoModel(videoChapters: any): VideoChaptersModel {
		videoChapters = videoChapters || {};
		const chapters = videoChapters.chapters || [];

		return new VideoChaptersModel(
			this.shapeChapters(chapters, videoChapters.chapterUri, videoChapters.chapterThumbnailUri)
		);
	}

	public getViewerIdOverlays(features: IMediaFeatures, viewerIdEnabledLocal: boolean, customText?: string): IVideoOverlay[] {
		const overlay = constructViewerIdOverlay(features.viewerIdSettings, this.UserContext, this.UserLocalIPService, viewerIdEnabledLocal, customText);
		return overlay ? [overlay] : undefined;
	}
}
