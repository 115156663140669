import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
	forwardRef
} from '@angular/core';

import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { isString, noop } from 'rev-shared/util';

import { ThemeService } from 'rev-portal/branding/Theme.Service';

import styles from './VbUiCheckbox.Component.module.less';

export enum VbUiCheckboxState {
	CHECKED = 'checked',
	MIXED = 'mixed',
	UNCHECKED = 'unchecked'
}

/**
 * Checkbox common component. Supports either 2 or 3 states. Input may either be a boolean or of type VbUiCheckboxState.
 */
@Component({
	selector: 'vb-ui-checkbox',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => VbUiCheckboxComponent),
			multi: true
		}
	],
	host: {
		'[class]': 'styles.root',
		'(keyup.space)': 'onClick()',
		'(keyup.enter)': 'onClick()'
	},
	template: `
		<label
			#wrapperLabel
			layout="row"
			layout-wrap="false"
			[attr.tabindex]="disableTabFocus ? -1 : 0">

			<button
				flex
				role="checkbox"
				[attr.aria-checked]="ariaCheckedState"
				[attr.aria-label]="accessibilityLabel"
				class="theme-accent-bg theme-accent-txt"
				type="button"
				[ngClass]="[
					styles.button,
					isChecked ? styles.checked : '',
					disabled ? styles.disabled : ''
				]"
				[disabled]="disabled"
				(blur)="onTouched()"
				(click)="onClick()"
				tabindex="-1">

				<span
					[ngClass]="styles.icon"
					class="glyphicons ok_2"
					[hidden]="!isChecked">
				</span>

				<div
					*ngIf="mixedStateEnabled"
					class="theme-accent-bg height-full"
					[ngClass]="styles.mixed"
					[hidden]="!isMixed"></div>
			</button>

			<div
				[ngClass]="styles.label"
				flex="fill"
				flex-align-self="center">
				<ng-content></ng-content>
			</div>

		</label>
	`
})
export class VbUiCheckboxComponent implements ControlValueAccessor, OnChanges {
	@Input() public accessibilityLabel: string;
	@Input()
	@HostBinding('class.vbUiCheckboxDisabled')
	public disabled: boolean;
	@Input() public mixedStateEnabled: boolean;
	@Input() public autoFocus: boolean;
	@Input() public disableTabFocus: boolean;

	@Output() public touched = new EventEmitter<void>();

	public readonly styles = styles;
	@ViewChild('wrapperLabel', { static: true })
	public wrapperLabel: ElementRef;

	private _currentState: VbUiCheckboxState = VbUiCheckboxState.UNCHECKED;
	private onChangeCallback: (value: any) => void = noop;
	private onTouchedCallback: () => void = noop;

	constructor(
		private ThemeService: ThemeService
	) {}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.autoFocus && this.autoFocus) {
			this.setAutoFocus();
		}
	}

	public onClick(): void {
		this.currentState = this._currentState === VbUiCheckboxState.UNCHECKED ?
			VbUiCheckboxState.CHECKED :
			VbUiCheckboxState.UNCHECKED;
	}

	public onTouched(): void {
		this.touched.emit();
		this.onTouchedCallback();
	}

	public registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	public writeValue(value: any): void {
		if (isString(value)) {
			this._currentState = value as VbUiCheckboxState;
		} else {
			this._currentState = this.valueToState(value);
		}
	}

	public get accentColor(): string {
		return this.ThemeService.accentColor;
	}

	public get ariaCheckedState(): boolean | 'mixed' {
		return this.currentState === VbUiCheckboxState.MIXED
			? 'mixed' : this.currentState === VbUiCheckboxState.CHECKED;
	}

	public get currentState(): VbUiCheckboxState {
		return this._currentState;
	}

	public set currentState(value: VbUiCheckboxState) {
		if (value !== this._currentState) {
			this._currentState = value;
			this.onChangeCallback(this.mixedStateEnabled ? value : this.stateToChecked(value));
			this.onTouched();
		}
	}

	public get isChecked(): boolean {
		return this.currentState === VbUiCheckboxState.CHECKED;
	}

	public get isMixed(): boolean {
		return this.currentState === VbUiCheckboxState.MIXED;
	}

	private stateToChecked(value: VbUiCheckboxState): boolean {
		return value === VbUiCheckboxState.CHECKED;
	}

	private valueToState(value: any): VbUiCheckboxState {
		return value ?
			VbUiCheckboxState.CHECKED :
			VbUiCheckboxState.UNCHECKED;
	}

	private setAutoFocus(): void {
		this.wrapperLabel?.nativeElement?.focus();
	}
}
