// make sure that this matches the enum server-side
export enum LicenseType {
	BY_NAMED_USERS = 'ByUsers',
	BY_HOURS = 'ByHours',
	ACTIVE_USERS = 'ActiveUsers',
	DISTRIBUTION_HOURS = 'DistributionHours',
	CONCURRENT_USERS = 'ConcurrentUsers'
}

export enum ModuleType {
	FullRev = 'FullRev',
	Distribution = 'Distribution',
	Ecdn = 'Ecdn'
}

export enum FeatureName {
	USER_SEAT = 'Rev_Active_User_Seat',
	ACTIVE_USER_SEAT = 'Rev_Monthly_Active_User_Seat',
	VIEWING_HOURS = 'Rev_Viewing_Hours',
	DISTRIBUTION_HOURS = 'Rev_Distribution_Hours',
	AI_HOURS = 'Rev_AI_Hours',
	CONCURRENT_USERS = 'Rev_Concurrent_Users'
}
