import { Component } from '@angular/core';

import type { AgEvent, GridApi, ICellRendererParams, RowNode } from '@ag-grid-community/core';
import type { ICellRendererAngularComp } from '@ag-grid-community/angular';

export const ACTION_ICONS_CELL_CLICK: string = 'actionIconsClick';

import styles from './ActionIconCellRenderer.module.less';

export interface IActionIconButton {
	action: IActionIconCellRendererType;
	iconClass: string;
	buttonClass: string;
	actionProp?: string;
	helperIconClass?: string;
	getHelperIconText?: (val: any) => string;
	helperIconText?: string;
}

export interface IActionIconCellRendererDef {
	actions: IActionIconButton[];
}

export enum IActionIconCellRendererType {
	Copy = 'copy',
	Download = 'download',
	Dialog = 'dialog'
}

export interface ActionIconsClickEvent extends AgEvent {
	action: string;
	actionProp: string;
	rowNode: RowNode;
	type: string;
}

interface IActionIconsRendererParams extends ICellRendererParams, IActionIconCellRendererDef { }

@Component({
	selector: 'action-icons-cell-renderer',
	templateUrl: 'ActionIconCellRenderer.Component.html'
})
export class ActionIconsRendererComponent implements ICellRendererAngularComp {
	public readonly styles = styles;

	private gridApi: GridApi;

	public rowNode: RowNode;
	public actions: IActionIconButton[];
	public textVal: string;
	public data: any;

	public agInit(params: IActionIconsRendererParams): void {
		this.updateCell(params);
	}

	public refresh(params: IActionIconsRendererParams): boolean {
		this.updateCell(params);
		return true; // nothing to do on refresh, so signal success
	}

	private updateCell(params: IActionIconsRendererParams): void {
		this.textVal = params.value;
		this.actions = params.actions;
		this.gridApi = params.api;
		this.rowNode = params.node;
		this.data = params.node.data;

		this.actions.forEach(action => {
			if (action.helperIconClass) {
				action.helperIconText = action.getHelperIconText?.(this.data);
			}
		});
	}

	public onClick(buttonDef: IActionIconButton): void {
		this.gridApi.dispatchEvent({
			action: buttonDef.action,
			actionProp: buttonDef.actionProp,
			rowNode: this.rowNode,
			type: ACTION_ICONS_CELL_CLICK
		} as ActionIconsClickEvent);
	}

}
