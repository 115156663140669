import { Injectable, Provider } from '@angular/core';
import { HttpXsrfTokenExtractor } from '@angular/common/http';

import { CsrfTokenCookie } from 'rev-shared/security/Tokens';
import { getCookie } from 'rev-shared/util/CookieUtil';

/**
 * Replaces the default HttpXsrfCookieExtractor in order to facilitate a dynamic cookie name.
 * Normally this is a static string injectable. However, ours is determined on the fly.
 * This makes it consistent with the AngularJS side.
 */
@Injectable()
export class VbXsrfCookieExtractor implements HttpXsrfTokenExtractor {
	public getToken(): string {
		return getCookie(CsrfTokenCookie, true);
	}
}

export const VbXsrfCookieExtractorProvider: Provider = {
	provide: HttpXsrfTokenExtractor,
	useClass: VbXsrfCookieExtractor
};
