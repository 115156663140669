import { Component, ElementRef, HostListener, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { ParentUIViewInject, UIView } from '@uirouter/angular';
import { SortChangedEvent } from '@ag-grid-community/core';
import { StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, Subscription } from 'rxjs';

import { ApprovalStatus, UNCATEGORIZED } from 'rev-shared/media/MediaConstants';
import { CategoryService } from 'rev-shared/media/Category.Service';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { DialogService } from 'rev-shared/ui/dialog/Dialog.Service';
import { ICancellableQueue, createPromiseQueue } from 'rev-shared/util/PromiseUtil';
import { ICategory } from 'rev-shared/media/Media.Contract';
import { IGetInfiniteScrollRows } from 'rev-shared/ui/dataGrid/infiniteScroll/IGetInfiniteScrollRows';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { IVbUiInfiniteScrollGridDataSourceResult } from 'rev-shared/ui/dataGrid/infiniteScroll/IVbUiInfiniteScrollGridDataSourceResult';
import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';
import { MediaQueryService } from 'rev-shared/ui/size/MediaQuery.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { noop } from 'rev-shared/util';
import { orderBy } from 'rev-shared/util/SortUtil';

import { IVideoPlaybackConfig } from 'rev-portal/media/videos/videoPlayback/IVideoPlaybackConfig';
import { MediaStateService, MediaViewMode, MediaType } from 'rev-portal/media/MediaState.Service';
import { SearchFilterStateService } from 'rev-portal/media/search/SearchFilterState.Service';
import { SearchService } from 'rev-portal/search/Search.Service';
import { VideoSelectionModelService } from 'rev-portal/media/search/bulkEdit/VideoSelectionModel.Service';

import { TABLE_DATA_MODE, getTableDataMode, sortFieldMapping, ITableViewApi } from './tableLayout/Contract';
import { VideoResultsSidebarButton } from './VideoSearchResultsTypes';

import styles from './Search.module.less';

type ICancellablePromise = Promise<any> & { cancelled: boolean };

@Component({
	selector: 'video-search-results',
	templateUrl: './VideoSearchResults.Component.html',
	host: {
		'[class]': 'styles.videoSearchResultsRoot',
		layout: 'column',
		'layout-wrap': 'false',
		flex: 'fill'
	},
})
export class VideoSearchResultsComponent implements OnDestroy, OnInit {
	@Input() public accountId: string;
	@Input() public bulkEdit: boolean;
	@Input() public categoryId: string;
	@Input() public categoryRoot: boolean;
	@Input() public expirationState: boolean;
	@Input() public forceMediaView: MediaViewMode;
	@Input() public mediaFeatures: any;
	@Input() public searchParams: any;
	@Input() public teamId: string;
	@Input() public userHasEditableVideos: boolean;
	@ViewChild('rightSidebarContainerRef') public rightSidebarContainerRef: ElementRef;

	public readonly videoPageSize: number = 25;
	public pageSize: number = this.videoPageSize;
	public readonly styles = styles;
	public readonly approvalStatusOptions = ApprovalStatus;
	public readonly TableDataMode = TABLE_DATA_MODE;
	public readonly VideoResultsSidebarButton = VideoResultsSidebarButton;

	private blockReload: boolean;
	private isTableSortingInProgress: boolean;
	private categoryPath: any[];
	private currentLoadOperation: ICancellablePromise;
	public query: string;
	private readonly promiseQueues: Array<ICancellableQueue<any>> = [];
	private scrollId: string;
	private subscription: Subscription = new Subscription();
	private totalVideos: number;
	private unsubscribePush: IUnsubscribe;
	private userId: string;
	private tableViewApi: ITableViewApi;
	private categoryLoaded: boolean;
	private readonly sortFieldMapping = sortFieldMapping;
	private previousActiveElement: any;

	public categories: any[];
	public isInitializing: boolean;
	public category: any;
	public hasMediaEditAuth: boolean;
	public isGuest: boolean;
	public isLoadComplete: boolean;
	public mediaState: any;
	public pauseInfiniteScroll: boolean;
	public scrollExpired: boolean;
	public selectionModel: any;
	public sortAscending: boolean;
	public sortField: string;
	public status: { [key: string]: boolean } = { active: true };
	public uncategorizedCatEntry: any;
	public videos: any[];
	public viewMode: string;
	public isTableMode: boolean;
	public currentStateName: string;
	public tableDataMode: TABLE_DATA_MODE;
	public isAccountAdminUser: boolean;

	public mediaQuery$: Observable<any> = this.MediaQueryService.getObservable(MaxWidthSmallMediaQuery)
		.pipe(
			map(isSmallSize => ( { isSmallSize }))
		);

	public getTableLayoutRows: IGetInfiniteScrollRows;

	public getVideosRows: IGetInfiniteScrollRows = params => {
		return this.loadGridViewData(params).then<IVbUiInfiniteScrollGridDataSourceResult>(result => {
			if (this.status.loading) {
				this.status = { active: true };
			}
			return {
				data: result.videos,
				scrollId: result.scrollId,
				totalRows: result.totalHits
			};
		});
	};

	public getBaseCategoriesRows: IGetInfiniteScrollRows = params => {
		return this.loadRootCategoriesTableViewData().then<IVbUiInfiniteScrollGridDataSourceResult>(result => {
			return {
				data: result.categories?.map(cat => ({ ...cat, fullWidth: true })),
				scrollId: result.scrollId,
				totalRows: result.totalHits
			};
		});
	};

	public getCategoryAndVideoRows: IGetInfiniteScrollRows = params => {
		return this.getVideosRows(params).then<IVbUiInfiniteScrollGridDataSourceResult>(result => {
			let data = [];
			const categoriesCount = this.categories?.length || 0;
			if (!params.scrollId && this.categories?.length) {
				const categories = this.categories.map(cat => ({ ...cat, fullWidth: true }));
				data = [...categories];
			}
			data = [...data, ...result.data];
			return {
				data,
				scrollId: result.scrollId,
				totalRows: result.totalRows + categoriesCount
			};
		});
	};

	constructor(
		private $state: StateService,
		private CategoryService: CategoryService,
		private DateParsers: DateParsersService,
		private DialogService: DialogService,
		private MediaQueryService: MediaQueryService,
		private MediaStateService: MediaStateService,
		private PushBus: PushBus,
		private SearchFilterState: SearchFilterStateService,
		private SearchService: SearchService,
		private SecurityContext: SecurityContextService,
		private UserContext: UserContextService,
		private VideoSelectionModel: VideoSelectionModelService,
		private translate: TranslateService,
		@Inject(UIView.PARENT_INJECT) private parent: ParentUIViewInject
	) {
		this.isAccountAdminUser = !!this.SecurityContext.checkAuthorization('admin.accounts.edit');
	}

	public ngOnInit(): void {
		this.isGuest = this.UserContext.isGuest();
		this.mediaState = this.MediaStateService.getMediaState();
		this.query = this.searchParams.query;
		this.sortAscending = !this.MediaStateService.getIsSortDesc();
		this.sortField = this.MediaStateService.getSortField();
		this.viewMode = this.forceMediaView || this.MediaStateService.getViewMode();
		this.isTableMode = this.viewMode === MediaViewMode.TABLE;
		this.pauseInfiniteScroll = false;
		this.userId = this.UserContext.getUser().id;
		this.hasMediaEditAuth = !!this.SecurityContext.checkAuthorization('media.edit');
		this.MediaStateService.searchResultsState = { searchQuery: this.query };
		this.currentStateName = this.parent.context.name;
		this.getTableLayoutRows = this.categoryRoot ? this.getBaseCategoriesRows : this.categoryId ? this.getCategoryAndVideoRows : this.getVideosRows;
		this.tableDataMode = getTableDataMode(this.expirationState, this.categoryRoot, this.categoryId);

		this.status = {
			loading: true
		};
		//tracking it for category. grid should not be loaded before categories fetch.
		//can not rely on status as it was messing it up when filter is applied.
		this.isInitializing = true;
		this.initialize()
			.then(() => this.status = { active: true })
			.catch(() => this.status = { error: true })
			.finally(() => this.isInitializing = false);

		this.subscription.add(this.SearchFilterState.change$.subscribe(() => this.reloadVideos()));
	}

	public ngOnDestroy(): void {
		this.subscription.unsubscribe();

		if (this.unsubscribePush) {
			this.unsubscribePush();
		}

		this.promiseQueues.forEach(queue => queue.cancel());

		this.closeScrollId();
	}

	@HostListener('window:blur')
	public onWindowBlur(): void {
		this.previousActiveElement = document.activeElement;
	}

	@HostListener('focusout')
	public onFocusout(): void {
		this.previousActiveElement = null;
	}

	public onSidebarButtonFocus(currentElement: HTMLButtonElement | HTMLAnchorElement): void {
		if (this.previousActiveElement === document.activeElement) {
			return;
		}

		setTimeout(() => {
			if (
				(currentElement.id === VideoResultsSidebarButton.FILTERS && !this.$state.current.name.includes('.filters-sidebar')) ||
				(currentElement.id === VideoResultsSidebarButton.BULK_EDIT_SIDEBAR && !this.$state.current.name.includes('.bulk-edit-sidebar'))
			) {
				currentElement.click();
			}
		});
	}

	public onSidebarButtonArrowUp(currentElement: HTMLButtonElement | HTMLAnchorElement): void {
		const sidebarButtons = document.querySelectorAll('.legacySidebarBtn');
		const nextElementIndex = this.getCurrentElementIndex(currentElement, sidebarButtons) - 1;

		if (nextElementIndex < 0 || !sidebarButtons?.[nextElementIndex]) { return; }

		(sidebarButtons[nextElementIndex] as HTMLButtonElement | HTMLAnchorElement)?.focus();
	}

	public onSidebarButtonArrowDown(currentElement: HTMLButtonElement | HTMLAnchorElement): void {
		const sidebarButtons = document.querySelectorAll('.legacySidebarBtn');
		const nextElementIndex = this.getCurrentElementIndex(currentElement, sidebarButtons) + 1;

		if (nextElementIndex > sidebarButtons.length || !sidebarButtons?.[nextElementIndex]) { return; }

		(sidebarButtons[nextElementIndex] as HTMLButtonElement | HTMLAnchorElement)?.focus();
	}

	private getCurrentElementIndex(currentElement: HTMLAnchorElement | HTMLButtonElement, list: NodeList): number {
		let index = null;
		list.forEach((btn: HTMLAnchorElement | HTMLButtonElement, i) => {
			if (btn.id === currentElement.id) {
				index = i;
			}
		});
		return index;
	}

	public get isOpen(): boolean {
		return this.rightSidebarContainerRef?.nativeElement.classList.contains('is-open');
	}

	public get activeSidebarButtonId(): string {
		let activeButtonId = '';
		if (this.$state.current.name.includes('.filters-sidebar')) {
			activeButtonId = VideoResultsSidebarButton.FILTERS;
		} else if (this.$state.current.name.includes('.bulk-edit-sidebar')) {
			activeButtonId = VideoResultsSidebarButton.BULK_EDIT_SIDEBAR;
		}
		return activeButtonId;
	}

	public readonly forceLoadAllSearchResults = (): Promise<void> => {
		this.blockReload = true;

		return this.loadRemaining()
			.finally(() => this.blockReload = false);
	};

	public get videoPlaybackConfig(): IVideoPlaybackConfig {
		return this.MediaStateService.videoPlaybackConfig;
	}

	private get hasLockedVideos(): boolean {
		return this.VideoSelectionModel.allSelectedVideoInLegalHold;
	}

	private get allSelectedVideosAreLiveRecordings(): boolean {
		return this.VideoSelectionModel.allSelectedVideosAreLiveRecordings;
	}

	private getVideoFromVideos(videoId: string): any {
		return this.videos.find(video => video.id === videoId);
	}

	public hasEditVideoAuth(video: any): boolean {
		return video && (video.editAcl || []).includes(this.userId);
	}

	private initialize(): Promise<void> {
		if (this.categoryRoot) {
			return this.initializeCategoryRoot()
				.then(() => {
					this.pageSize = (this.categories?.length || 0) + this.videoPageSize;
				});
		}

		if (this.bulkEdit) {
			this.initializeBulkEdit();
		}

		this.initializePush();

		if (this.isTableMode) {
			return this.categoryId ?
				this.initCategoryContext()
					.then(() => {
						this.pageSize = (this.categories?.length || 0) + this.videoPageSize;
					})
			: Promise.resolve();
		}
		this.initCategoryContext();
		return this.loadNextPageInternal();

	}

	private initializeBulkEdit(): void {
		this.selectionModel = this.VideoSelectionModel;

		this.MediaStateService.searchResultsState.getSelectedCount = () =>
			this.VideoSelectionModel.selectionCount;

		this.loadLegalHoldVideoCount();
	}

	private initializeCategoryRoot(): Promise<any> {
		//Videos list is not shown on root categories page
		this.pauseInfiniteScroll = true;

		return this.CategoryService.getRootCategories()
			.then(result => {
				this.categories = result.categories;
				this.uncategorizedCatEntry = {
					isUncategorized: true,
					id: UNCATEGORIZED,
					videoCount: result.uncategorized?.videoCount,
					ready: true,
					name: this.translate.instant('Media_Uncategorized')
				} as any;
			});
	}

	private initCategoryContext(): Promise<void> {
		if (!this.categoryId || this.categoryId === UNCATEGORIZED) {
			this.categoryPath = this.searchParams.isUncategorized ? [{ id: null }] : undefined;

			this.MediaStateService.searchResultsState = {
				...this.MediaStateService.searchResultsState,
				categoryPath: this.categoryPath
			};
			return Promise.resolve();
		}

		const categoryContent = this.searchParams.categoryContent;
		if (categoryContent) {
			const categoryPath = categoryContent.path;

			const categories = categoryContent.categories;
			this.category = categoryPath[categoryPath.length - 1];
			this.categoryPath = categoryPath;
			this.MediaStateService.searchResultsState = {
				...this.MediaStateService.searchResultsState,
				categoryPath
			};

			this.categories = categories;
		}
		return Promise.resolve();
	}

	private initializePush(): void {
		this.unsubscribePush = this.PushBus.subscribe(this.accountId, 'Media.Videos', {
			VideoAnalyzed: data => {
				const video = this.getVideoFromVideos(data.videoId);
				if (video) {
					video.duration = this.DateParsers.parseTimespan(data.duration);
					video.status = data.status;
				}
			},

			OriginalVideoInstanceReplaced: data => {
				const video = this.getVideoFromVideos(data.videoId);
				if (video) {
					video.duration = this.DateParsers.parseTimespan(data.duration);
					video.status = data.status;
				}
			},

			OriginalVideoInstanceSwitched: data => {
				this.setStatus(data.videoId, data.status);
			},

			VideoProcessingFailed: data => {
				const video = this.getVideoFromVideos(data.videoId);
				if (video) {
					video.status = VideoStatus.PROCESSING_FAILED;
				}
			},

			VideoTranscoded: data => {
				this.setStatus(data.videoId, VideoStatus.READY);
			},

			VideoFastStartSet: data => {
				this.setStatus(data.videoId, data.status);
			},

			VideoInstanceStoringFinished: data => {
				this.setStatus(data.videoId, data.status);
			}
		});
	}

	private isReady(video: any): boolean { // unused?
		return video.status === VideoStatus.READY;
	}

	private setStatus(videoId: string, status: VideoStatus): void {
		const video = this.getVideoFromVideos(videoId);

		if (video) {
			video.status = status;
		}
	}

	private loadLegalHoldVideoCount(): Promise<void> {
		return this.SearchService.getFilteredVideos({
			accountId: this.accountId,
			legalHold: true,
			parsedQuery: this.SearchFilterState.buildQuery(),
			count: 0,
			noScroll: true
		})
			.then(result => this.VideoSelectionModel.setLegalHoldVideos(result.totalHits));
	}

	public loadNextPage(): void {
		if (!this.categoryRoot && !this.currentLoadOperation && !this.isLoadComplete) {
			this.loadNextPageInternal();
		}
	}

	public resetDataModel(): void {
		this.videos = [];
		this.scrollId = null;
		this.isLoadComplete = false;
		this.currentLoadOperation = undefined;
		this.loadNextPage();
	}

	private loadNextPageInternal(): Promise<void> {
		this.pauseInfiniteScroll = true;
		this.scrollExpired = false;
		const thisLoadOperation = this.currentLoadOperation = this.SearchService.getVideos(
			{
				...this.getVideoSearchParams(),
				count: this.videoPageSize,
				scrollId: this.scrollId
			})
			.then(result => {
				if ((thisLoadOperation as any).cancelled) {
					this.SearchService.closeScrollId(this.accountId, result.scrollId);
					return;
				}

				this.videos = this.videos ? [...this.videos, ...result.videos] : result.videos;
				this.totalVideos = result.totalHits;
				this.scrollId = result.scrollId;

				if (this.bulkEdit) {
					this.VideoSelectionModel.setVideos(this.videos, this.totalVideos);
				}

				this.isLoadComplete = this.videos.length >= this.totalVideos;
				this.pauseInfiniteScroll = this.isLoadComplete;
				this.currentLoadOperation = null;

				if(!this.MediaStateService.searchResultsState.mediaType || this.MediaStateService.searchResultsState.mediaType === MediaType.VIDEO) {
					this.MediaStateService.searchResultsState = {
						...this.MediaStateService.searchResultsState,
						categoryPath: this.categoryPath,
						mediaCount: this.totalVideos,
						mediaType: MediaType.VIDEO
					};
				}
			})
			.catch(err => {
				if (err?.status === 404) {
					this.scrollExpired = true;
				}
			}) as ICancellablePromise;
		return thisLoadOperation;
	}

	private loadGridViewData(params: any): Promise<any> {
		if(this.isTableSortingInProgress) {
			return Promise.resolve();
		}
		//revisit all these conditions.
		this.sortField = this.sortFieldMapping[params.sortField]
			? this.sortFieldMapping[params.sortField] : params.sortField != null ? params.sortField : this.sortField;

		this.sortAscending = params.sortField != null ? params.isSortAscending : this.sortAscending;

		return this.SearchService.getVideos(
			{
				...this.getVideoSearchParams(),
				count: this.videoPageSize,
				scrollId: params.scrollId
			})
			.then(result => {
				this.totalVideos = result.totalHits;
				this.scrollId = result.scrollId;

				//we should not maintain videos list here for table view. we should rely on ag-grid.
				//we are doing it now to satisfy VideoSelectionModel. Next phase, fix VideoSelectionModel to depend of ag-grid values.
				this.videos = this.videos ? [...this.videos, ...result.videos] : result.videos;
				this.isLoadComplete = this.videos.length >= this.totalVideos;
				if (this.bulkEdit) {
					this.VideoSelectionModel.setVideos(this.videos, this.totalVideos);
				}

				if(!this.MediaStateService.searchResultsState.mediaType || this.MediaStateService.searchResultsState.mediaType === MediaType.VIDEO) {
					this.MediaStateService.searchResultsState = {
						...this.MediaStateService.searchResultsState,
						categoryPath: this.categoryPath,
						mediaCount: this.totalVideos,
						mediaType: MediaType.VIDEO
					};
				}
				return result;
			});
	}

	public loadRootCategoriesTableViewData(): Promise<any> {
		return Promise.resolve()
			.then(() => {
				const categories = [...(this.categories || [])];
				if (this.uncategorizedCatEntry) {
					categories.push(this.uncategorizedCatEntry);
				}
				return {
					categories,
					scrollId: undefined,
					totalHits: categories?.length + 1
				};
			});
	}

	public initiateInventoryReportDownload(): void {
		this.SearchService.getVideos({
			...this.getVideoSearchParams(),
			downloadSearch: true,
		})
			.then(() => this.openDownloadCsvDialog())
			.catch(noop);
	}

	private openDownloadCsvDialog(): void {
		const params = {
			title: this.translate.instant('InitiateDownload'),
			message: this.translate.instant('InitiateDownload_Confirmation'),
			actionText: this.translate.instant('Ok')
		};
		this.DialogService.openConfirmationDialog(params).result
			.then(() => noop)
			.catch(noop);
	}

	private getVideoSearchParams(): any {
		const parsedQuery = this.SearchFilterState.buildQuery();

		return {
			accountId: this.accountId,
			query: this.query,
			parsedQuery,
			useEditAcl: this.searchParams.useEditAcl,
			sortField: this.sortField,
			sortAscending: this.sortAscending,
			subtitles: true
		};
	}

	private loadRemaining(): Promise<any> {
		if (!this.isLoadComplete) {
			return this.tableViewApi.loadNexBlockData().then(() => this.loadRemaining());
		}

		return Promise.resolve();
	}

	private onReset(): void {
		this.VideoSelectionModel.reset();
		this.tableViewApi.resetSelection();
	}

	private reloadVideos(): void {
		if (!this.blockReload) {
			if (this.currentLoadOperation) {
				this.currentLoadOperation.cancelled = true;
			}

			this.closeScrollId();

			if (this.bulkEdit) {
				this.loadLegalHoldVideoCount();
				this.onReset();
			}

			Object.assign(this, {
				videos: [],
				status: { loading: true },
				pauseInfiniteScroll: false
			});

			if (this.isTableMode) {
				this.tableViewApi.reloadTableView();
			} else {
				this.loadNextPageInternal()
					.then(() => this.status = { active: true })
					.catch(() => this.status = { error: true });
			}
		}
	}

	public onTableViewReady(api: ITableViewApi): void {
		this.tableViewApi = api;
		this.subscription.add(this.tableViewApi.sortChanged.subscribe(event => this.onTableViewSort(event)));
	}

	private onTableViewSort(event: SortChangedEvent): void {
		const colState = event.columnApi.getColumnState();
		const sortState = colState
			.filter(s => s.sort != null)
			.map(s => ( { colName: this.sortFieldMapping[s.colId] || s.colId, sortDescending: s.sort === 'desc' }))[0];

		const { sortField, desc, viewMode } = this.MediaStateService.getQueryParams();

		if (sortField === sortState.colName && desc === sortState.sortDescending) {
			return;
		}
		this.isTableSortingInProgress = true;
		this.MediaStateService.setSortField(this.sortFieldMapping[sortState.colName] || sortState.colName);
		this.MediaStateService.setIsSortDesc(sortState.sortDescending);

		//grid should not reload view again. This is needed because
		//in case of table view, dynamic true can not be used for params and
		//and dynamic: true will be used then it will break tiles view.
		this.$state.go('.', this.MediaStateService.getQueryParams(), { reload: true });
	}

	public sortVideos(field: string, defaultDescending: boolean): void {
		this.MediaStateService.setIsSortDesc(this.sortField === field ? this.sortAscending : defaultDescending);
		this.MediaStateService.setSortField(field);
		this.$state.go('.', this.MediaStateService.getQueryParams(), { reload: true });
	}

	private closeScrollId(): void {
		if(this.scrollId) {
			this.SearchService.closeScrollId(this.accountId, this.scrollId);
			this.scrollId = null;
		}
	}

	public showSpinner(): boolean {
		return this.status.loading ||
			this.status.active && !this.isTableMode && !this.videos && !this.categoryRoot;
	}
}
