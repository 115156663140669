//portal related states
export const PORTAL_ROOT_STATE = `portal`;

export const DASHBOARD_STATE = `${PORTAL_ROOT_STATE}.dashboard`;
export const PORTAL_ADMIN_LOADER_STATE = `${PORTAL_ROOT_STATE}.load-admin`;
export const PORTAL_ADMIN_STATE = `${PORTAL_ROOT_STATE}.admin`;

export const PORTAL_MEDIA_STATE = `${PORTAL_ROOT_STATE}.media`;
export const PORTAL_MEDIA_ALL_STATE = `${PORTAL_MEDIA_STATE}.all`;
export const PORTAL_MEDIA_UPLOADS_STATE = `${PORTAL_MEDIA_STATE}.uploads`;

export const PORTAL_UPLOAD_STATE = `${PORTAL_ROOT_STATE}.upload`;
export const PORTAL_UPLOAD_ADD_STATE = `${PORTAL_UPLOAD_STATE}.add`;
