import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Observer, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StateService } from '@uirouter/angular';
import { TranslateService } from '@ngx-translate/core';

import { UploadMenuComponent } from 'rev-portal/navigation/uploadMenu/UploadMenu.Component';
import { WebcastVideoSource } from 'rev-portal/scheduledEvents/webcast/WebcastVideoSource';
import { ZoomService, IZoomMeeting } from 'rev-portal/scheduledEvents/zoom/Zoom.Service';

import { DTMF_CODES_REGEX } from 'rev-shared/util/directives/form/validation/validationUtil';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { addQueryParams } from 'rev-shared/util/Util.Service';
import { captureSetupHeight, captureSetupWidth } from 'rev-capture/Capture.Constants';
import { getInitialDtmfCodeFn } from 'rev-shared/media/DtmfUtils';
import { isIe } from 'rev-shared/util/UserAgentUtil';

import { RecordingService } from './Recording.Service';

import './recordingMenuComponent.less';

import styles from './RecordingMenu.Component.module.less';

interface IRecentVcCallsResponse {
	recentVcCalls: IRecentVcCall[]
}

interface IRecentVcCall {
	sipAddress: string,
	sipPin?: number,
	when: string
}

interface IVCRecord {
	isDisabled: boolean;
	sipPin?: string;
	sipUrl?: string;
	title?: any;
	microsoftTeamsMeetingUrl?: string;
	vcDtmf?: string;
}

interface IWebexTeam {
	name: string;
	roomId: string;
	sipAddress: string;
}

const popupCfg = 'toolbar=no,location=no,status=no,menubar=no';
let recordingPopup: Window;

@Component({
	selector: 'recording-menu',
	templateUrl: './RecordingMenu.Component.html'
})
export class RecordingMenuComponent implements OnInit, OnChanges {
	@Input() public teamId: string;
	@Input() public onPrem: boolean;
	@Input() public webexTeamsEnabled: boolean;
	@Input() public vciEnabled: boolean;
	@Input() public zoomEnabled: boolean;
	@Input() public zoomSipAddressSuffix: string;
	@Input() public isWebrtcSinglePresenterDisabled: boolean;
	@Input() public pexipEnabled: boolean;
	@Input() public mediaFeatures: IMediaFeatures;

	public readonly vciMsTeamsEnabled: boolean = false; //VCI Teams disabled
	public readonly DTMF_CODES_REGEX = DTMF_CODES_REGEX;

	@ViewChild('form') public form: NgForm;

	public readonly WebcastVideoSource = WebcastVideoSource;
	public readonly styles = styles;

	public vcRecord: IVCRecord = { isDisabled: false };
	public sipAddresses$: Observable<string[]>;
	public webexTeam: IWebexTeam;
	public zoomMeetingId: string = '';
	public zoomMeetingPassword: string;

	public selectedRecordingSource: WebcastVideoSource;
	public translations: any;
	public disableCapture: boolean = true;

	private getInitialDtmfCode = getInitialDtmfCodeFn();

	public get zoomMeetings(): IZoomMeeting[] {
		const meetings = this.ZoomService.filterMeetings(this.zoomMeetingId ?? '');
		if (!meetings) {
			return [];
		}

		return meetings;
	}

	constructor(
		private RecordingService: RecordingService,
		private StateService: StateService,
		private TranslateService: TranslateService,
		private UploadMenu: UploadMenuComponent,
		public ZoomService: ZoomService
	) {}

	public ngOnInit(): void {
		this.sipAddresses$ = this.getRecentCalls$();
		this.disableCapture = isIe() ||
			!navigator.mediaDevices?.getUserMedia ||
			this.onPrem ||
			this.isWebrtcSinglePresenterDisabled;

		this.autoSelectRecordingSource();

		if(this.zoomEnabled && !this.ZoomService.meetings) {
			this.ZoomService.loadMeetings();
		}
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if ((changes.webexTeamsEnabled || changes.vciEnabled || changes.zoomEnabled || changes.pexipEnabled) && !this.selectedRecordingSource) {
			this.autoSelectRecordingSource();
		}
	}

	public onSubmit(): void {
		if(recordingPopup && !recordingPopup.closed) {
			recordingPopup.focus();
			this.UploadMenu.closeUploadMenu();
			return;
		}

		if (this.selectedRecordingSource === WebcastVideoSource.CAPTURE) {
			this.openCaptureWindow();
			this.UploadMenu.closeUploadMenu();
			return;
		}

		this.vcRecord.isDisabled = true;
		this.startRecording();
		this.vcRecord.sipUrl = '';
	}

	public onRecordingSourceChange(): void {
		this.vcRecord.vcDtmf = this.getInitialDtmfCode(this.selectedRecordingSource);
	}

	private shapeVcRecordingData(): void {
		if (this.selectedRecordingSource === WebcastVideoSource.SIP_ADDRESS) {
			this.vcRecord.title = this.vcRecord.sipUrl;
		} else if (this.selectedRecordingSource === WebcastVideoSource.PEXIP) {
			this.vcRecord.title = this.vcRecord.sipUrl;
		} else if (this.selectedRecordingSource === WebcastVideoSource.WEBEX) {
			this.vcRecord.title = this.webexTeam.name;
			this.vcRecord.sipUrl = this.webexTeam.sipAddress;
		} else if (this.selectedRecordingSource === WebcastVideoSource.ZOOM) {
			const meetings = this.ZoomService.filterMeetings(this.zoomMeetingId);
			this.zoomMeetingId = this.zoomMeetingId.replace(/-/g, '');
			this.vcRecord.title = meetings && meetings.length > 0 ? meetings[0].topic : `${this.TranslateService.instant('Zoom_Meeting')} - ${this.zoomMeetingId}`;
			this.vcRecord.sipUrl = this.zoomMeetingPassword
				? `${this.zoomMeetingId}.${this.zoomMeetingPassword}@${this.zoomSipAddressSuffix}`
				: `${this.zoomMeetingId}@${this.zoomSipAddressSuffix}`;
			this.vcRecord.sipPin = null;
		} else if (this.selectedRecordingSource === WebcastVideoSource.MSTEAMS) {
			this.vcRecord.title = this.vcRecord.microsoftTeamsMeetingUrl;
		}
	}

	public get integrationEnabled(): boolean {
		return !this.disableCapture ||
			this.webexTeamsEnabled ||
			this.vciEnabled ||
			this.zoomEnabled ||
			this.pexipEnabled ||
			this.vciMsTeamsEnabled;
	}

	private startRecording(): void {
		// It's decided that user will not enter bitrate info.
		// 0 entered here means to use the default value
		// defined in the config file.

		this.shapeVcRecordingData();
		this.RecordingService
			.recordVideoConference(this.vcRecord.title, this.vcRecord.sipUrl, this.vcRecord.sipPin, 0, this.vcRecord.microsoftTeamsMeetingUrl, this.teamId, this.vcRecord.vcDtmf)
			.then(({ id }) => {
				window.setTimeout(() => this.redirectToPlayback(id), 1500);
			})
			.catch(error => this.handleErrorScenario(error));
	}

	private redirectToPlayback(videoId: string): void {
		this.StateService.go('portal.video', { videoId })
			.finally(() => this.vcRecord.isDisabled = false);
	}

	private getRecentCalls$(): Observable<string[]> {
		// Typeahead can accept an observable as input
		// Map http repsonse to a filtered list based on what was being inputted
		return new Observable((observer: Observer<string>) => {
			observer.next(this.vcRecord.sipUrl);
		}).pipe(
			switchMap((query: string) => {
				if (query) {
					return this.RecordingService.recentVideoConferenceCalls().pipe(
						map((data: IRecentVcCallsResponse) => {
							return data?.recentVcCalls
								.filter(entry => entry.sipAddress.includes(query))
								.map(entry => entry.sipAddress) ?? [];
						}),
						catchError(err => {
							console.error(err);
							return of([]);
						})
					);
				}
				return of([]);
			})
		);
	}

	private handleErrorScenario(error: any): void {
		this.vcRecord.isDisabled = false;
		if(error.hasIssue && error.hasIssue('RecordingHoursNotAvailable')) {
			this.form.form.setErrors({ 'invalid': true });
		} else {
			console.error('Error starting the recording conference', error);
		}
	}

	private autoSelectRecordingSource(): void {
		this.selectedRecordingSource = [WebcastVideoSource.CAPTURE, WebcastVideoSource.SIP_ADDRESS, WebcastVideoSource.WEBEX, WebcastVideoSource.ZOOM, WebcastVideoSource.MSTEAMS]
			.find(source => this.isRecordingSourceAllowed(source));
	}

	private isRecordingSourceAllowed(source: WebcastVideoSource): boolean {
		switch(source) {
			case WebcastVideoSource.CAPTURE:
				return !this.disableCapture;
			case WebcastVideoSource.SIP_ADDRESS:
				return this.vciEnabled;
			case WebcastVideoSource.WEBEX:
				return this.webexTeamsEnabled;
			case WebcastVideoSource.ZOOM:
				return this.zoomEnabled;
			case WebcastVideoSource.MSTEAMS:
				return this.vciMsTeamsEnabled;
			case WebcastVideoSource.PEXIP:
				return this.pexipEnabled;
		}
		return true;
	}

	private openCaptureWindow(): void {
		const width = captureSetupWidth;
		const height = captureSetupHeight;
		const screen: any= window.screen;
		const left = (window.screenLeft ?? screen.left ?? screen.availLeft ?? 0) + (screen.availWidth - width) / 2;
		const top = (window.screenTop ?? screen.top ?? screen.availTop ?? 0) + (screen.availHeight - height) / 2;

		const url = addQueryParams('/capture', { channelId: this.teamId });

		recordingPopup = window.open(url, '', `${popupCfg},width=${width}, height=${height}, top=${top}, left=${left}`);
	}
}
