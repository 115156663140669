import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	HostListener,
	ViewChildren,
	QueryList,
	ElementRef
} from '@angular/core';

import { StateService } from '@uirouter/angular';

import { MaxWidthSmallMediaQuery } from 'rev-shared/ui/size/Size.Constants';

import {
	ISidebarConfig,
	ISidebarButtonConfig
} from './ISidebarConfig';

import styles from './VbUiSidebar.Component.module.less';

export interface ISidebarToggleEvent {
	activeSidebarButtonId: string;
	isOpen: boolean;
}

export enum VbUiSidebarSlot {
	HEADER_CONTROLS = 'headerControls',
	PANEL_BODY = 'panelBody'
}

@Component({
	selector: 'vb-ui-sidebar',
	templateUrl: './VbUiSidebar.Component.html',
	host: { // layout attribute is specified below with a HostBinding
		'[class]': 'hostClass',
		'layout-gt-md': 'row',
		'layout-wrap': 'false'
	}
})
export class VbUiSidebarComponent implements OnChanges, OnInit, OnDestroy {
	@Input() public isMobileLayoutDisabled: boolean;
	@Input() public openWidthPx: number;
	@Input() public sidebarConfig: ISidebarConfig;
	@Input() public themed: boolean;
	@Input() public noBorder: boolean;
	@Output() public onToggle = new EventEmitter<ISidebarToggleEvent>();
	@ViewChildren('sidebarButtonsRef') public sidebarButtonsRef: QueryList<ElementRef>;

	public buttonConfigLastClick: ISidebarButtonConfig;
	public isMobileWindow: boolean;
	private mobileMediaQuery: MediaQueryList;
	private mobileMediaQueryListener: () => void;
	private previousActiveElement: any;

	@HostBinding('class.vbUiSidebarRootOpen')
	public isOpen: boolean = false;
	private defaultOpenedOnce: boolean = false;
	public hostClass: {[key: string]: boolean};
	public enableAnimation: boolean;

	public readonly styles = styles;
	private readonly autoButtonIds = new Set<string>();

	constructor(
		private $state: StateService
	) {}

	public ngOnInit(): void {
		// listen for the mobile media query
		this.mobileMediaQuery = window.matchMedia(MaxWidthSmallMediaQuery);
		this.mobileMediaQueryListener = () => this.onMobileMediaQueryChange();
		this.mobileMediaQuery.addEventListener('change', this.mobileMediaQueryListener);


		// initial run since you won't get an event on init
		this.onMobileMediaQueryChange();
		this.hostClass = {
			[this.styles.root]: true,
			[this.styles.mobileLayoutDisabled]: this.isMobileLayoutDisabled
		};

		setTimeout(() => {
			this.enableAnimation = true;
		}, 500);

	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.sidebarConfig && this.sidebarConfig) {
			if(!this.defaultOpenedOnce && !this.activeSidebarButtonId) {
				this.openDefaultSidebarPanel();
			}
			if (this.sidebarConfig.buttons.every(b => !b.visible || b.id !== this.activeSidebarButtonId)) {
				this.closeSidebar();
			}

			this.sidebarConfig.buttons = this.sidebarConfig.buttons.filter(button => button.visible);

			this.sidebarConfig.buttons.forEach(button => {
				if(button.activeOnVisible && button.visible && !this.autoButtonIds.has(button.id)) {
					this.autoButtonIds.add(button.id);
					setTimeout(() => {
						this.buttonConfigLastClick = button;
						this.toggleSidebar(true);
					});
				}
			});
		}
	}

	public ngOnDestroy(): void {
		this.mobileMediaQuery.removeListener(this.mobileMediaQueryListener);
	}

	public get activeSidebarButtonId(): string {
		return this.isOpen && this.buttonConfigLastClick ?
			this.buttonConfigLastClick.id :
			undefined;
	}

	@HostBinding('attr.layout')
	public get layout(): string {
		return this.isMobileLayoutDisabled ?
			'row' :
			'column';
	}

	public closeSidebar(): void {
		this.toggleSidebar(false);
	}

	private onMobileMediaQueryChange(): void {
		this.isMobileWindow = this.mobileMediaQuery.matches;
	}

	private openDefaultSidebarPanel(): void {
		if (this.sidebarConfig.defaultClickedButtonId && !this.isOpen) {
			this.buttonConfigLastClick = this.getButtonConfig(this.sidebarConfig.defaultClickedButtonId);
			this.defaultOpenedOnce = true;

			//fixing ExpressionChangedAfterItHasBeenCheckedError...
			setTimeout(() => this.toggleSidebar(true));
		}
	}

	private getButtonConfig(buttonId: string): ISidebarButtonConfig {
		return this.sidebarConfig.buttons
			.find(button => button.id === buttonId);
	}

	public onSidebarButtonArrowUp(btnIndex: number): void {
		this.setFocusedButton(btnIndex - 1);
	}

	public onSidebarButtonArrowDown(btnIndex: number): void {
		this.setFocusedButton(btnIndex + 1);
	}

	public onSidebarButtonArrowLeft(event: Event) {
		event.stopPropagation();
		event.preventDefault();
	}

	public onSidebarButtonArrowRight(event: Event) {
		event.stopPropagation();
		event.preventDefault();
	}

	private setFocusedButton(index: number): void {
		const sidebarButtons = this.sidebarButtonsRef?.toArray();
		if (!sidebarButtons?.[index]) { return; }

		(sidebarButtons[index] as ElementRef).nativeElement.focus();
	}

	public onSidebarButtonEnter(button: ISidebarButtonConfig): void {
		this.triggerSidebarButton(button);
	}

	@HostListener('window:blur')
	public onWindowBlur(): void {
		this.previousActiveElement = document.activeElement;
	}

	@HostListener('focusout')
	public onFocusout(): void {
		this.previousActiveElement = null;
	}

	public onSidebarButtonFocus(button: ISidebarButtonConfig): void {
		if (
			this.previousActiveElement === document.activeElement ||
			button.uiSref ||
			button.onClick ||
			(this.isOpen && button.id === this.activeSidebarButtonId)
		) {
			return;
		}
		this.triggerSidebarButton(button);

		// temporary solution for buttons with notification "addSidebarNotificationEventHandler" causes browser to lose focus
		// due to "assignSidebarModel" being reassigned
		// fix requires bigger refactoring effort on sidebar notifications
		if (button.notification) {
			setTimeout(() => {
				document.getElementById(button.id).focus();
			});
		}
	}

	public onSidebarButtonMousedown(e: Event, buttonConfig: ISidebarButtonConfig): void {
		e.stopImmediatePropagation();

		if (
			document.activeElement.id === buttonConfig.id ||
			buttonConfig.uiSref ||
			buttonConfig.onClick ||
			(this.isOpen && buttonConfig.id === this.activeSidebarButtonId)
		) {
			this.triggerSidebarButton(buttonConfig);
			if (buttonConfig.uiSref) {
				document.getElementById(buttonConfig.id).focus();
			}
			e.preventDefault();
		} else if ((e.target as Element).id === buttonConfig.id) {
			// mousedown doesn't focus so use the focus event to trigger sidebar (ex: first time opening)
			document.getElementById(buttonConfig.id).focus();
		} else {
			// stop any other default browser action
			e.preventDefault();
		}
	}

	public triggerSidebarButton(buttonConfig: ISidebarButtonConfig): void {
		if (buttonConfig.uiSref) {
			const url = this.$state.href(buttonConfig.uiSref, buttonConfig.uiParams);
			window.open(url, '_blank');
			return;
		}

		const isSameAsLastClick: boolean = this.buttonConfigLastClick && this.buttonConfigLastClick.id === buttonConfig.id;
		this.defaultOpenedOnce = true;

		if (buttonConfig.onClick) {
			buttonConfig.onClick();
			return;
		}

		const isOpen: boolean = isSameAsLastClick ?
			!this.isOpen :
			true;

		this.buttonConfigLastClick = buttonConfig;

		this.toggleSidebar(isOpen);
	}

	private toggleSidebar(isOpen: boolean): void {
		this.isOpen = isOpen;

		this.onToggle.emit({
			activeSidebarButtonId: this.activeSidebarButtonId,
			isOpen
		});
	}

	public getNotificationCountDisplay(count: number): number | string {
		return count <= 99 ? count : '99+';
	}

}
