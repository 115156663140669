import { IVideoOverlay } from '@vbrick/vbrick-player/app/player/videoOverlay/IVideoOverlay';

export enum VIDEO_OVERLAY_TYPE {
	VIEWER_ID = 'ViewerId',
	PUSH_CONTENT = 'PushContent'
}

export type VideoOverlay = IVideoOverlay & {
	type?: VIDEO_OVERLAY_TYPE
};
