import { Injectable } from '@angular/core';

import { IRevDisplay, RevDisplayContext } from 'rev-shared/contract/RevDisplayContext';

import { DASHBOARD_STATE } from './AppStateNames';
import { DistributionDisplayStrategy } from '../contract/DistributionDisplayStrategy';
import { PortalDisplayStrategy } from '../contract/PortalDisplayStrategy';

@Injectable({
	providedIn: 'root'
})

export class AppBootstrapService {
	private landingState = DASHBOARD_STATE;
	private landingStateParams;
	private revDisplayContext: RevDisplayContext;

	public appConfig: any;

	constructor(
	) {
		this.revDisplayContext = new RevDisplayContext(new PortalDisplayStrategy());
	}


	public setAppLandingState(state: string, params?: any) {
		this.landingState = state;
		this.landingStateParams = params;
	}

	public getAppLandingStateName(): string {
		return this.landingState;
	}

	public getAppLandingState(): { state: string, params: any} {
		return {
			state: this.landingState,
			params: this.landingStateParams ?? undefined
		};
	}

	public loadRevDistributionDisplayContext(isEcdn: boolean): void {
		this.revDisplayContext.setStrategy(new DistributionDisplayStrategy(isEcdn));
	}

	public getRevDisplayContext(): IRevDisplay {
		return this.revDisplayContext.getDisplay();
	}

	public setAppConfig(config: any) {
		this.appConfig = { ...(this.appConfig || {}), ...config };
	}
}
