<div [ngClass]="styles.cell">
	<vb-profile-picture [profileUrl]="item[insight.insightOptions.fieldDefs.profileImageUri]" [placeholderIconClass]="insight.getIconClass(item)" [altTxt]="item[insight.insightOptions.fieldDefs.display]"></vb-profile-picture>
</div>

<div [ngClass]="styles.descriptionCell">
	<span>{{ item[insight.insightOptions.fieldDefs.display] }}</span>
	<span *ngIf="item[insight.insightOptions.fieldDefs.subDisplay]" [ngClass]="styles.subDisplay">
		{{ item[insight.insightOptions.fieldDefs.subDisplay] }}
	</span>
</div>

<div [ngClass]="styles.cell">
	<button type="button" class="btn btn-edit-access badge" [(ngModel)]="insight.insightOptions.moderatorWithSlideControl" btnCheckbox [btnCheckboxFalse]="false" [btnCheckboxTrue]="item.id">
		<span [ngClass]="item.id === insight.insightOptions.moderatorWithSlideControl? 'ok_2' : 'minus'" class="glyphicons"></span>
		{{ 'Event_SlideControlAccess' | translate }}
	</button>
</div>

<div>
	<button type="button" [attr.aria-label]="'Remove' | translate " class="btn btn-link pull-right remove-assigned-item" (click)="insight.removeItem(item)" [attr.aria-label]="'RemoveItemType' | translate : {
			'0': item[insight.insightOptions.fieldDefs.display],
			'1': (insight.insightOptions.dataTypeLabels[item.type] | translate)
		}">
		<span class="glyphicons remove_2"></span>
	</button>
</div>
