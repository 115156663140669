import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { ICommandPromise } from 'rev-shared/push/PushService';
import { PushService } from 'rev-shared/push/PushService';
import { UserContextService } from 'rev-shared/security/UserContext.Service';

import { IPlaylist } from './IPlaylist';
import { VideoService } from './Video.Service';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { IMediaFeatures } from './IMediaFeatures';

export const FEATURED_VIDEOS_PLAYLIST_ID = 'featured';

@Injectable({
	providedIn: 'root'
})
export class PlaylistService {
	constructor(
		private DateParsers: DateParsersService,
		private PushService: PushService,
		private UserContext: UserContextService,
		private VideoService: VideoService,
		private http: HttpClient
	) {}

	public addFeaturedVideo(video: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:AddFeaturedVideo', video);
	}

	public addVideoToPlaylists(videoPlaylists: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:AddVideoToPlaylists', videoPlaylists);
	}

	public createPlaylist(playlist: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:CreatePlaylist', playlist);
	}

	public deletePlaylist(playlistId: string): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:DeletePlaylist', { playlistId });
	}

	public getFeaturedVideos(accountId: string, fetchActiveVideosOnly?: boolean, mediaFeatures?: IMediaFeatures): Promise<any> {
		return lastValueFrom(this.http.get<any>(`/media/videos/accounts/${accountId}/featured-videos`,
			{
				params: this.getIsActiveParam(fetchActiveVideosOnly)
			}))
			.then(result => {
				result.whenVideoAddedOrDeleted = result.whenVideoAddedOrDeleted || {};
				result.whenVideoAddedOrDeleted.when = this.DateParsers.parseUTCDate(result.whenVideoAddedOrDeleted.when);
				result.createdBy.when = this.DateParsers.parseUTCDate(result.createdBy.when);

				result.videos.forEach((video, playlistIndex) => Object.assign(video, {
					duration: this.DateParsers.parseTimespan(video.duration),
					thumbnailUri: video.thumbnailUri || '',
					thumbnailCfg: this.VideoService.getThumbnailCfg(video.thumbnailCfg),
					uploaderFirstName: video.uploaderFirstName || '',
					subtitles: (video.transcriptionFiles || []).map(this.VideoService.toPlayerDirectiveSubtitle),
					playlistIndex,
					ownerUserId: video.owner.userId || '',
					ownerUsername: video.owner.username || '',
					ownerFirstName: video.owner.firstName || '',
					ownerLastName: video.owner.lastName || '',
					ownerProfileImageUri: video.owner.profileImageUri,
					ownerFullName: this.shapeOwnerFullName(video.owner?.firstName, video.owner?.lastName),
					ratingsCount: video.ratingCount,
					videoOverlays: mediaFeatures ? this.VideoService.getViewerIdOverlays(mediaFeatures, video.viewerIdEnabled) : undefined
				}));

				return Object.assign(result, {
					count: result.videos.length,
					featured: true,
					id: FEATURED_VIDEOS_PLAYLIST_ID
				});
			});
	}

	public getPlaylist(playlistId: string, fetchActiveVideosOnly?: boolean): Promise<any> {
		if (playlistId === FEATURED_VIDEOS_PLAYLIST_ID) {
			return this.getFeaturedVideos(this.UserContext.getAccount().id, fetchActiveVideosOnly);
		}

		return lastValueFrom(this.http.get<any>(`/media/playlists/${playlistId}`,
			{
				params: this.getIsActiveParam(fetchActiveVideosOnly)
			}))
			.then(result => {
				//TODO: profileimageuri is not included (pre-existing)
				result.videos?.forEach((v, i) => Object.assign(v, {
					whenUploaded: this.DateParsers.parseUTCDate(v.whenUploaded),
					duration: this.DateParsers.parseTimespan(v.duration),
					uploaderFirstName: v.uploaderFirstName || '',
					playlistIndex: i + 1,
					ownerUserId: v.owner.userId || '',
					ownerUsername: v.owner.username || '',
					ownerFirstName: v.owner.firstName || '',
					ownerLastName: v.owner.lastName || '',
					ownerProfileImageUri: v.owner.profileImageUri,
					ownerFullName: this.shapeOwnerFullName(v.owner?.firstName, v.owner?.lastName),
					ratingsCount: v.ratingCount
				}));

				return result;
			});
	}

	public getUserPlaylists(userId: string): Promise<IPlaylist[]> {
		return lastValueFrom(this.http.get<any>(`/media/users/${userId}/playlists`))
			.then(result => {
				result.playlists.forEach(p => {
					p.whenVideoAddedOrDeleted = p.whenVideoAddedOrDeleted || {};
					p.whenVideoAddedOrDeleted.when = this.DateParsers.parseUTCDate(p.whenVideoAddedOrDeleted.when);
					p.createdBy.when = this.DateParsers.parseUTCDate(p.createdBy.when);
				});

				return result.playlists;
			});
	}

	public modifyFeaturedVideos(videoIds: any[]): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:SaveFeaturedVideos', { videoIds });
	}

	public modifyPlaylist(playlist): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:SavePlaylistDetails', playlist);
	}

	public removeFeaturedVideo(video: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:RemoveFeaturedVideo', video);
	}

	public removeVideoFromPlaylists(videoPlaylists: any): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:RemoveVideoFromPlaylists', videoPlaylists);
	}

	private getIsActiveParam(isActive: boolean): HttpParams {
		return isActive ? new HttpParams().set('isActive', 'true') : undefined;
	}

	private shapeOwnerFullName(firstName: string, lastName: string): string {
		return `${firstName || ''} ${lastName || ''}`.trim();
	}
}
