import { IPushContentLink } from 'rev-portal/scheduledEvents/common/PushContentTypes';

import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { UserLocalIPService } from 'rev-shared/security/UserLocalIP.Service';
import { escape } from 'rev-shared/util';
import { getViewerFullName, getViewerId, getViewerIdInfo } from 'rev-shared/viewerId/ViewerIdHelper';

import { VideoOverlay, VIDEO_OVERLAY_TYPE } from './VideoOverlay';

import styles from './VideoOverlay.module.less';

const PLAYER_SMALL_SIZE_THRESHOLD = 505;
const PLAYER_MEDIUM_SIZE_THRESHOLD = 700;
const onPushContentOverlayCloseBtnClicked = (link: IPushContentLink, parentElement: HTMLElement) => {
	parentElement.querySelector<HTMLElement>(`#container-${link.id}`).removeEventListener('click', () => onPushContentOverlayCloseBtnClicked);
	parentElement.querySelector<HTMLElement>(`#container-${link.id}`).remove();
	link.isCloseBtnClicked = true;
};

export function constructViewerIdOverlay(
	accViewerIdSettings: any,
	userContext: UserContextService,
	userLocalIPService: UserLocalIPService,
	localViewerIdEnabled: boolean,
	customText?: string
): VideoOverlay {

	if (!accViewerIdSettings) {
		return;
	}

	const viewerId = getViewerId(userContext, userLocalIPService);
	const viewerIdInfo = getViewerIdInfo(accViewerIdSettings, viewerId, localViewerIdEnabled, userContext, customText);

	if (!viewerIdInfo) {
		return;
	}

	const overlayInformation = [];
	const overlayFirstLine = [];
	let y = 20;
	if (viewerIdInfo.fullName) {
		overlayFirstLine.push(viewerIdInfo.fullName);
	}
	if (viewerIdInfo.id) {
		overlayFirstLine.push(viewerIdInfo.id);
	}
	if (overlayFirstLine) {
		overlayInformation.push(`
${formatViewerIdSvgInfo(0, y, overlayFirstLine.join(' / '))}
`);
		y += 20;
	}
	overlayInformation.push(`
${formatViewerIdSvgInfo(0, y, viewerIdInfo.customMsg)}
`);
	const id = Math.random();
	return {
		id,
		content: `
<svg viewBox="200 0 2300 1500" id="diagonal-overlay-${id}" width='100%' height='100%'>
	<defs>
		<pattern id="pattern-${id}" patternUnits='userSpaceOnUse' width='22%' height='10%' patternTransform='scale(3.1) rotate(-20)'>
			${overlayInformation.join('\n')}
		</pattern>
	</defs>
	<rect width='800%' height='800%' transform='translate(0,-1800)' fill="url(#pattern-${id})"/>
</svg>
`,
		forcedOverlayRecreation: true,
		type: VIDEO_OVERLAY_TYPE.VIEWER_ID
	};
}

function formatViewerIdSvgInfo(x: number, y: number, info: string): string {
	return `<text stroke-width=".2" stroke="black" stroke-opacity=".8" fill-opacity=".8" letter-spacing="1" fill="white" x="${x}" y="${y}">${escape(info)}</text>`;
}

export function constructPushContentOverlay(accPushContentEnabled: boolean, manualLinks: IPushContentLink[]): VideoOverlay {
	if (!accPushContentEnabled || !manualLinks.length) {
		return;
	}

	const id = Math.random();
	return {
		id,
		content: `
			<div class="${styles.pushContentContainer}">
				${constructPushContentOverlays(manualLinks)}
			</div>
		`,
		forcedOverlayRecreation: false,
		type: VIDEO_OVERLAY_TYPE.PUSH_CONTENT,
		cssClasses: [styles.pushContentWrapper]
	};
}

export function refreshViewerIdOverlay(video: any, prevPlayerSize: { width: number, height: number }, currentPlayerSize: { width: number, height: number }): void {
	if (!video.videoOverlays?.some(v => v.type === VIDEO_OVERLAY_TYPE.VIEWER_ID)) {
		return;
	}
	if (prevPlayerSize?.width !== currentPlayerSize.width && prevPlayerSize?.height !== currentPlayerSize.height) {
		const prevOverlays = video.videoOverlays;
		video.videoOverlays = prevOverlays.filter(vo => vo.type !== VIDEO_OVERLAY_TYPE.VIEWER_ID);
		setTimeout(() => {
			video.videoOverlays = prevOverlays.map(m => m);
		});
	}
}

function constructPushContentOverlays(contentLinks: IPushContentLink[]): string {
	let pushContentItemsHTML = '';

	contentLinks.forEach(link => {
		if (!link.isCloseBtnClicked && link.isEnabled) {
			pushContentItemsHTML += `
				<a href="${link.link}" target="_blank" id="container-${link.id}">
					<div class="${styles.pushContent}">
						<div id="close-${link.id}" class="glyphicons remove_2 ${styles.pushContentClose}" onclick="event.preventDefault();"></div>
						<div class="${styles.pushContentName}">${link.name}</div>
						<div class="${styles.pushContentLink}">${link.link}</div>
					</div>
				</a>
			`;
		}
	});

	return pushContentItemsHTML;
}

export function setPushContentOverlayStyleAttributes(overlays: VideoOverlay[], playerSize: { height: number, width: number }): void {
	if (!overlays?.length) {
		return;
	}

	const pushContentOverlay = overlays.find(overlay => overlay.type === VIDEO_OVERLAY_TYPE.PUSH_CONTENT);

	if(!pushContentOverlay) {
		return;
	}

	const wrapperHeight = playerSize?.width >= PLAYER_MEDIUM_SIZE_THRESHOLD ? 80 : 40;
	const wrapperVerticalPadding = playerSize?.width >= PLAYER_MEDIUM_SIZE_THRESHOLD ? 10 : 5;
	const detailsVerticalPadding = playerSize?.width >= PLAYER_MEDIUM_SIZE_THRESHOLD ? 10 : 2;
	const nameFontSize = playerSize?.width > PLAYER_MEDIUM_SIZE_THRESHOLD ? 1 : .75;
	const linkFontSize = playerSize?.width > PLAYER_MEDIUM_SIZE_THRESHOLD ? .75 : .5;

	document.body.style.setProperty('--push-content-name-font-size', nameFontSize + 'rem');
	document.body.style.setProperty('--push-content-link-font-size', linkFontSize + 'rem');
	document.body.style.setProperty('--push-content-height', wrapperHeight + 'px');
	document.body.style.setProperty('--push-content-wrapper-vertical-padding', wrapperVerticalPadding + 'px');
	document.body.style.setProperty('--push-content-details-vertical-padding', detailsVerticalPadding + 'px');
}

export function addPushOverlayCloseBtnListeners(addedOverlay: VideoOverlay, parentElement: HTMLElement, manualLinks: IPushContentLink[]) {
	if (addedOverlay.type !== VIDEO_OVERLAY_TYPE.PUSH_CONTENT || !parentElement || !manualLinks?.length) {
		return;
	}

	manualLinks.forEach(link => {
		const closeBtnRef = parentElement.querySelector(`#close-${link.id}`);
		if (closeBtnRef && link.isEnabled && addedOverlay.content.includes(link.id)) {
			closeBtnRef.addEventListener('click', () => onPushContentOverlayCloseBtnClicked(link, parentElement));
		}
	});
}

export function removePushOverlayBtnListeners(overlay: VideoOverlay, parentElement: HTMLElement, manualLinks: IPushContentLink[]) {
	if (overlay.type !== VIDEO_OVERLAY_TYPE.PUSH_CONTENT || !parentElement || !manualLinks?.length) {
		return;
	}

	const containerIds = parsePushContentOverlayIds(overlay.content);
	manualLinks.forEach(link => {
		const closeBtnRef = parentElement.querySelector(`#close-${link.id}`);
		if (link.isEnabled && !link.isCloseBtnClicked && closeBtnRef && containerIds.includes(`container-${link.id}`)) {
			// to prevent duplicate listeners
			closeBtnRef.removeEventListener('click', () => onPushContentOverlayCloseBtnClicked(link, parentElement));
		}
	});
}

function parsePushContentOverlayIds(contentString: string): string[] {
	const parser = new DOMParser();
	const content = parser.parseFromString(contentString, 'text/html');
	const ids = Array.from(content.querySelectorAll('a')).map(container => container.id);
	return ids;
}
