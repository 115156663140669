<div class="dropdown dropup" dropdown [hidden]="playbackOptions.length < 2" (isOpenChange)="onToggleInternal($event)">
	<button class="btn icon-button" type="button" dropdownToggle>
		<span class="glyphicon glyphicon-cog"></span>
	</button>

	<ul class="pull-right" *dropdownMenu>
		<li *ngFor="let playbackOption of playbackOptions; first as isFirst">
			<button type="button" class="dropdown-item" [ngClass]="{ selected: selectedPlayback.url.startsWith(playbackOption.url) }" (click)="updateSelection(playbackOption)">
				{{ isFirst ? ('Media_Videos_Playback_Quality_Auto' | translate) : playbackOption.label }}
			</button>
		</li>
	</ul>
</div>
