export enum VideoResultsSidebarButton {
	HOME = 'home',
	FILTERS = 'filters',
	BULK_EDIT = 'bulkEdit',
	BULK_EDIT_SIDEBAR = 'bulkEditSidebar',
	BULK_EDIT_DELETE_VIDEOS = 'bulkEditDeleteVideos',
	BULK_EDIT_DELETE_LEGALHOLD = 'bulkEditLegalHold',
	BULK_EDIT_CANCEL = 'bulkEditCancel',
	DOWNLOAD_CSV = 'downloadCsv'
}
