import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import '@uirouter/rx';

import {
	UIRouterGlobals,
	StateService
} from '@uirouter/angular';

import {
	BehaviorSubject,
	Observable,
	Subscription,
	combineLatest,
	EMPTY,
	from,
	merge,
	of,
	throwError,
	concat, Subject
} from 'rxjs';

import {
	catchError,
	distinctUntilChanged,
	distinctUntilKeyChanged,
	filter,
	first,
	map,
	pluck,
	shareReplay,
	switchMap,
	tap
} from 'rxjs/operators';

import { tag } from 'rxjs-spy/operators';
import { flatten } from 'underscore';

import { ITimeMarker } from 'vbrick-player-src/videogular/controls/VbTimeMarkers.Component';
import { SupportedPlaybacksService } from 'vbrick-player-src/SupportedPlaybacks.Service';

import { AccountLicenseService } from 'rev-shared/security/AccountLicense.Service';
import { ApprovalModel } from 'rev-shared/media/ApprovalModel';
import { ApprovalProcessService } from 'rev-shared/media/approvalProcess/ApprovalProcess.Service';
import { ApprovalStatus } from 'rev-shared/media/MediaConstants';
import { DateParsersService } from 'rev-shared/date/DateParsers.Service';
import { PushBus } from 'rev-shared/push/PushBus.Service';
import { IUnsubscribe } from 'rev-shared/push/IUnsubscribe';
import { IPushObservableMessage } from 'rev-shared/push/IPushObservableMessage';
import { ITranscodeInitiator } from 'rev-shared/media/TranscodeInitiatorType';
import { ImageContext } from 'rev-shared/media/ImageContext';
import { IMediaFeatures } from 'rev-shared/media/IMediaFeatures';
import { MediaFeaturesService } from 'rev-shared/media/MediaFeatures.Service';
import { PlaylistService } from 'rev-shared/media/Playlist.Service';
import { retryUntilSuccess } from 'rev-shared/util/PromiseUtil';
import { PushService, ICommandPromise } from 'rev-shared/push/PushService';
import { ResourceType } from 'rev-shared/videoPlayer/ResourceType';
import { SecurityContextService } from 'rev-shared/security/SecurityContext.Service';
import { SecurityRedirectHelperService } from 'rev-shared/security/SecurityRedirectHelper.Service';
import { UserContextService } from 'rev-shared/security/UserContext.Service';
import { VideoChaptersModel } from 'rev-shared/media/videoChapter/VideoChaptersModel';
import { VideoPlayerAdapterService } from 'rev-shared/videoPlayer/VideoPlayerAdapter.Service';
import { VideoService } from 'rev-shared/media/Video.Service';
import { VideoStatus } from 'rev-shared/media/VideoStatus';
import { getChapterMarkers } from 'rev-shared/videoPlayer/MarkerUtils';
import { lastValueFrom } from 'rev-shared/rxjs/lastValueFrom';
import { noop } from 'rev-shared/util';
import { VIDEO_PLAYBACK_ROUTE } from 'rev-shared/media/Constants';

import { PUBLIC_VIDEO_PASSWORD_STATE_NAME } from './Constants';
import { IVideoPlaybackConfig } from './IVideoPlaybackConfig';
import { IVideoPlaybackParams } from './IVideoPlaybackParameters';
import { IVideoPlaylist } from './VideoPlaylist.Contract';
import { IVideoPreposition } from './IVideoPreposition';

const USER_VIDEO_ROUTE = 'Media.UserVideo';

interface IInitialStartTime {
	startAt: number;
	startAtResume: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class VideoPlaybackService {
	//[x: string]: any;//is this needed?
	private static readonly ERROR_REASON_INVALID_PASSWORD: string = 'InvalidPassword';

	private readonly authorizedDeleteComment: boolean;
	private mediaFeatures: IMediaFeatures;
	private fromStateName: string;
	private initialVideoPlayback$: Observable<any>;
	private playbackConfig: IVideoPlaybackConfig;
	private playlistCollectionSubject$: BehaviorSubject<IVideoPlaylist[]>;
	private playlistSubject$: BehaviorSubject<any>;
	private preventPlaybackUpdates$: Observable<boolean>;
	private push$: Observable<IPushObservableMessage>;
	private rawParams$: Observable<IVideoPlaybackParams>;
	private subscriptions: Subscription[];
	private unsubscribePlaylistPushListeners: IUnsubscribe;
	private videoDownloadUrl$: Observable<string>;
	private videoSubject$: BehaviorSubject<any>;
	private playerTouchedSubject$: BehaviorSubject<boolean>;
	private readonly paramsSubject$: BehaviorSubject<IVideoPlaybackParams>;

	public readonly markers$: Observable<ITimeMarker[]>;
	public readonly playlist$: Observable<any>;
	public readonly playlistId$: Observable<string>;
	public readonly video$: Observable<any>;
	public readonly videoId$: Observable<string>;
	public readonly videoPlayableStatus$: Observable<VideoStatus>;
	public readonly videoPlayerParams$: Observable<IVideoPlaybackParams>;

	constructor(
		private http: HttpClient,
		private $state: StateService,
		private $uiRouterGlobals: UIRouterGlobals,
		private ApprovalProcessService: ApprovalProcessService,
		private DateParsers: DateParsersService,
		private MediaFeatures: MediaFeaturesService,
		private PlaylistService: PlaylistService,
		private PushBus: PushBus,
		private PushService: PushService,
		private SecurityContext: SecurityContextService,
		private SecurityRedirectHelper: SecurityRedirectHelperService,
		private SupportedPlaybacks: SupportedPlaybacksService,
		private UserContext: UserContextService,
		private VideoPlayerAdapter: VideoPlayerAdapterService,
		private VideoService: VideoService,
		private accountLicense: AccountLicenseService,
		private ngZone: NgZone
	) {

		this.authorizedDeleteComment = SecurityContext.checkAuthorization('media.deleteComment');
		this.playlistSubject$ = new BehaviorSubject<any>(null);
		this.videoSubject$ = new BehaviorSubject<any>(null);
		this.playlistCollectionSubject$ = new BehaviorSubject<IVideoPlaylist[]>([]);
		this.playerTouchedSubject$ = new BehaviorSubject<boolean>(false);
		this.paramsSubject$ = new BehaviorSubject<IVideoPlaybackParams>({} as any);

		// input parameters
		this.rawParams$ = merge(
			(this.$uiRouterGlobals.params$ as Observable<IVideoPlaybackParams>),
			this.paramsSubject$
		)
			.pipe(
				filter(data => !!data.videoId || !!data.playlistId),
				distinctUntilChanged(),
				tag<IVideoPlaybackParams>('rawParams$')
			);

		this.playlistId$ = this.rawParams$
			.pipe(
				distinctUntilKeyChanged('playlistId'),
				pluck('playlistId'),
				tag<string>('playlistId$')
			);

		this.videoId$ = this.rawParams$
			.pipe(
				distinctUntilKeyChanged('videoId'),
				pluck('videoId'),
				tag<string>('videoId$')
			);

		this.videoPlayerParams$ = this.videoId$
			.pipe(
				switchMap(videoId => this.getVideoPlayerParams(videoId)),
				tag<IVideoPlaybackParams>('videoPlayerParams$')
			);

		// entities
		this.video$ = this.videoSubject$
			.asObservable()
			.pipe(
				distinctUntilChanged(),
				tap(() => this.ngZone.run(noop)),
				tag<any>('video$')
			);

		this.playlist$ = this.playlistSubject$
			.asObservable()
			.pipe(
				filter(playlist => !!playlist),
				distinctUntilKeyChanged('playlistId'),
				tag<any>('playlist$')
			);

		this.videoPlayableStatus$ = this.video$
			.pipe(
				switchMap(video => this.getVideoPlayableStatus(video)),
				distinctUntilChanged(),
				tag<VideoStatus>('videoPlayableStatus$')
			);

		this.markers$ = this.video$.pipe(
			map(getChapterMarkers),
			map(markers => flatten(markers))
		);

		this.push$ = this.videoId$
			.pipe(
				switchMap(videoId => this.getPush$(videoId)), // combined push subscription observable, which will deliver a stream of partial video model changes
				pluck('data'),
				tag<IPushObservableMessage>('push$')
			);

		this.initialVideoPlayback$ = this.videoId$
			.pipe(
				filter(videoId => !!videoId),
				switchMap(videoId => this.getInitialVideoPlayback(videoId)),
				tag<any>('initialVideoPlayback$')
			);

		this.videoDownloadUrl$ = this.video$
			.pipe(
				pluck<any, string>('downloadUrl'),
				filter(url => !!url),
				distinctUntilChanged(),
				tag<any>('downloadUrl$')
			);

		const resetOnPlayableStatusChange$: Observable<boolean> = this.videoPlayableStatus$
			.pipe(
				map(() => false)
			);
		this.preventPlaybackUpdates$ = merge(
			resetOnPlayableStatusChange$,
			this.playerTouchedSubject$
		).pipe(
			shareReplay(1)
		);
	}

	public subscribePlaylistHandlers(): void {
		this.unsubscribePlaylistPushListeners = this.registerPlaylistPushListeners();
	}

	public unsubscribePlaylistHandlers(): void {
		this.unsubscribePlaylistPushListeners();
	}

	public deleteVideo(videoId: string): ICommandPromise<void> {
		return this.PushService.dispatchCommand('media:DeleteVideo', { videoId }, 'VideoDeleting');
	}

	public prepositionVideo(videoId: string, scheduleDateTime: Date, timezoneId: string): Promise<void> {
		return this.PushService.dispatchCommand('media:PrepositionVideo', {
			videoId,
			scheduleDateTime,
			timezoneId
		})
			.then(() => this.mergeVideoPartial({
				preposition: {
					lastDateTime: scheduleDateTime,
					lastTimezoneId: timezoneId
				}
			}));
	}

	public setLegalHold(videoId: string, isLegalHold: boolean): ICommandPromise<void> {
		const command: string = isLegalHold ?
			'media:LockVideo' :
			'media:UnlockVideo';

		return this.PushService.dispatchCommand(command, { videoId });
	}

	public start(fromStateName: string, config?: IVideoPlaybackConfig, mediaFeatures?: IMediaFeatures): void {
		this.fromStateName = fromStateName;
		this.playbackConfig = config || {} as any;

		if (this.subscriptions) {
			return;
		}

		this.subscriptions = [
			this.playlistId$.subscribe(playlistId => this.setPlaylistId(playlistId)),
			this.initialVideoPlayback$.subscribe(),
			this.applyFirstVideoToPlaylistIfNone().subscribe(),
			this.push$.subscribe(videoPartial => this.mergeVideoPartial(videoPartial)),
			this.preventPlaybackUpdates$.subscribe()
		];

		if(config.initialParams) {
			this.paramsSubject$.next(config.initialParams);
		}
		this.mediaFeatures = mediaFeatures;
	}

	public stop(): void {
		if (!this.subscriptions) {
			return;
		}

		this.subscriptions.forEach(subscription => subscription.unsubscribe());

		this.subscriptions = null;
		this.playlistSubject$.next(null);
		this.videoSubject$.next(null);
		this.paramsSubject$.next({} as any);
	}

	private applyFirstVideoToPlaylistIfNone(): Observable<any> {
		return combineLatest([this.playlist$, this.videoId$])
			.pipe(
				tap(([playlist, videoId]) => {
					// if playlist without a videoId param supplied, extract the first and redirect so that it gets picked up
					if (playlist && !videoId && playlist.videos && playlist.videos.length) {
						this.$state.go('.', { videoId: playlist.videos[0].id });
					}
				}),
				tag('applyFirstVideoToPlaylistIfNone')
			);
	}

	public get playlistCollection$(): Observable<IVideoPlaylist[]> {
		return this.playlistCollectionSubject$.asObservable();
	}

	public loadPlaylists(): void {
		this.playlistCollectionSubject$.next([]);
		this.getPlaylistCollections()
			.subscribe(playlists => this.playlistCollectionSubject$.next(playlists));
	}

	public playerTouched(): void {
		this.playerTouchedSubject$.next(true);
	}

	public savePlaylists(updatedPlaylists: IVideoPlaylist[], videoId: string): Promise<any> {
		const playlistsDifference: IVideoPlaylist[] = [];
		const initialPlaylists = this.playlistCollectionSubject$.value || [];

		(updatedPlaylists || [])
			.forEach(item => {
				if((initialPlaylists || []).find(initPlaylist => initPlaylist.id === item.id
					&& item.isSelected !== initPlaylist.isSelected)) {
					playlistsDifference.push(item);
				}
			});

		return Promise.all([
			this.updateFeaturedVideoPlaylist(
				videoId,
				playlistsDifference.find(item => item.id === 'featured')
			),
			this.updateUserPlaylists(videoId, playlistsDifference, true),
			this.updateUserPlaylists(videoId, playlistsDifference, false),
		]).then(() => this.updateVideoCountInPlaylistStream(playlistsDifference, videoId));
	}

	public createPlaylist(playlist: IVideoPlaylist): Promise<void> {
		return this.PlaylistService.createPlaylist({
			name: playlist.name,
			userId: this.UserContext.getUser().id
		});
	}

	public updatePlaylist(playlist: IVideoPlaylist): Promise<void> {
		playlist.isUpdateInProgress = true;
		this.updatePlaylistInStream(playlist);

		return this.PlaylistService.modifyPlaylist({
			playlistId: playlist.id,
			name: playlist.name,
			videoIds: playlist.videoIds
		}).finally(() => {
			playlist.isUpdateInProgress = false;
			playlist.editMode = false;
			this.updatePlaylistInStream(playlist);
		});
	}

	public approvalProcessTemplates$(userId:string = null): Observable<any> {
		return from(this.ApprovalProcessService.fetchUserApprovalProcessTemplates(userId)
			.then(() => ({
				submitterTemplates: this.ApprovalProcessService.userProcessTemplates,
				approverTemplates: this.ApprovalProcessService.approverProcessTemplates
			})));
	}

	public submitForApproval(videoId: string, templateId: string, ownerUserId: string): Promise<void> {
		return this.VideoService.submitForApproval({ videoId, templateId, ownerUserId });
	}

	public transcodeVideoForDownload(videoId: string, transcodeInitiator: ITranscodeInitiator): Observable<any> {
		const transcodeForDownloadPush$: Observable<IPushObservableMessage> = this.PushBus.getObservable(
			videoId, VIDEO_PLAYBACK_ROUTE,
			{
				VideoTranscoded: () => this.videoDownloadUrl$,
				VideoProcessingFailed: () => throwError(new Error())
			}
		);

		return of(this.VideoService.transcodeVideo(videoId, transcodeInitiator))
			.pipe(
				switchMap(() => transcodeForDownloadPush$.pipe(pluck('data'))),
				first()
			);
	}

	public clearTranscodeError(videoId: string): void {
		this.VideoService.clearTranscodeError(videoId)
			.then(() => this.retryGetPlaybackUntilSuccess())
			.then(response => this.mergeVideoPartial(response))
			.catch(err => Promise.reject(`Error clearing transcode error -> ${err}`));
	}

	public setVideoDownload(videoId: string, downloadUrl: string, isEditing: boolean = false): Promise<any> {
		return lastValueFrom(this.http.post('/reports/videos/download',
			{ videoId, downloadUrl },
			isEditing ? { params: { isEditing: isEditing.toString() } } : undefined ));
	}

	public updateVideoStatus(status: VideoStatus): void {
		this.mergeVideoPartial({ status });
	}

	private updateVideoCountInPlaylistStream(playlistsDifference: IVideoPlaylist[], videoId: string): void {
		const playlists: IVideoPlaylist[] = this.playlistCollectionSubject$.value || [];

		playlists.forEach(playlist => {
			const updatedPlaylist: IVideoPlaylist = playlistsDifference
				.find(diffPlaylist => diffPlaylist.id === playlist.id);
			if(updatedPlaylist) {
				playlist.isSelected = updatedPlaylist.isSelected;
				playlist.videoIds = updatedPlaylist.isSelected
					? (playlist.videoIds || []).concat(videoId)
					: (playlist.videoIds || []).filter(id => id !== videoId);
			}
		});
		this.playlistCollectionSubject$.next(playlists);
	}

	private updatePlaylistInStream(playlist: IVideoPlaylist): void {
		const playlists: IVideoPlaylist[] = this.playlistCollectionSubject$.value || [];
		this.playlistCollectionSubject$.next(playlists.map(
			item => item.id === playlist.id ? playlist : item
		));
	}

	private getModifiedUserPlaylist(playlists: IVideoPlaylist[], isVideoAdded: boolean): IVideoPlaylist[] {
		return (playlists || []).filter(playlist => playlist.id !== 'featured'
			&& playlist.isSelected === isVideoAdded);
	}

	private updateFeaturedVideoPlaylist(videoId: string, featuredPlaylist: IVideoPlaylist): Promise<void> {
		if (!this.SecurityContext.checkAuthorization('media.featuredPlaylist')
				|| !featuredPlaylist) {
			return Promise.resolve();
		}

		const payload: any = {
			accountId: this.UserContext.getAccount().id,
			videoId
		};

		return featuredPlaylist.isSelected
			? this.addVideoToFeaturedList(payload)
			: this.removeVideoFromFeaturedList(payload);
	}

	private addVideoToFeaturedList(payload: any): Promise<void> {
		return this.PlaylistService.addFeaturedVideo(payload);
	}

	private removeVideoFromFeaturedList(payload: any): Promise<void> {
		return this.PlaylistService.removeFeaturedVideo(payload);
	}

	private updateUserPlaylists(videoId: string, playlists: IVideoPlaylist[], isVideoAdded: boolean): Promise<void> {
		const modifiedPlaylists = this.getModifiedUserPlaylist(playlists, isVideoAdded);

		if(!modifiedPlaylists || modifiedPlaylists.length === 0) {
			return Promise.resolve();
		}

		const payload = {
			videoId,
			userId: this.UserContext.getUser().id,
			playlistIds: modifiedPlaylists.map(playlist => playlist.id)
		};

		return isVideoAdded
			? this.addVideoToUserPlaylists(payload)
			: this.removeVideoFromUserPlaylists(payload);
	}

	private addVideoToUserPlaylists(payload): Promise<any> {
		return this.PlaylistService.addVideoToPlaylists(payload);
	}

	private removeVideoFromUserPlaylists(payload): Promise<any> {
		return this.PlaylistService.removeVideoFromPlaylists(payload);
	}

	private getPlaylistCollections(): Observable<any> {
		const userId: string = this.UserContext.getUser().id;
		const accountId: string = this.UserContext.getAccount().id;

		return combineLatest([
			from(this.PlaylistService.getUserPlaylists(userId)),
			from(this.PlaylistService.getFeaturedVideos(accountId))
		]).pipe(
			map(([userPlaylists, featuredVideos]) =>
				[
					this.formatFeaturedVideoPlaylist(featuredVideos),
					...this.formatUsersPlaylists(userPlaylists)
				]
			)
		);
	}

	private formatUsersPlaylists(userPlaylists: any[]): IVideoPlaylist[] {
		const playlists: IVideoPlaylist[] = (userPlaylists || [])
			.map(playlist => {
				return {
					id: playlist.playlistId,
					name: playlist.name,
					videoIds: (playlist.videoThumbnails || []).map(item => item.id),
					isSelected: this.videoExistsInPlaylist(playlist.videoThumbnails),
					date: playlist.whenVideoAddedOrDeleted.when || playlist.createdBy.when
				};
			});

		return playlists;
	}

	private videoExistsInPlaylist(videoCollections: any[]): boolean {
		return (videoCollections || [])
			.some(item => item.id === this.videoSubject$.value.id);
	}

	private formatFeaturedVideoPlaylist(featuredVideos: any): IVideoPlaylist {
		return {
			id: featuredVideos.id,
			videoIds: (featuredVideos.videos || []).map(video => video.id),
			isSelected: this.videoExistsInPlaylist(featuredVideos.videos),
			date: featuredVideos.whenVideoAddedOrDeleted.when || featuredVideos.createdBy.when
		};
	}

	private getInitialStartTime(videoSession): Observable<IInitialStartTime> {
		return this.videoPlayerParams$
			.pipe(
				first(),
				map(videoPlayerParams => {
					const startAtParam = videoPlayerParams.parsedStartAt;

					if (startAtParam >= 0) {
						return {
							startAt: startAtParam,
							startAtResume: false
						};
					} else if (videoSession) {
						return {
							startAt: videoSession.time,
							startAtResume: true
						};
					}

					return null;
				}),
				tag('getInitialStartTime')
			);
	}

	private getInitialVideoPlayback(videoId: string): Observable<any> {
		return combineLatest([
			from(this.VideoService.getVideoPlayback(videoId, this.mediaFeatures)),
			this.getVideoSession()
		]).pipe(
			first(),
			switchMap(([response, videoSession]) => combineLatest([
				of(response),
				of(videoSession),
				this.getInitialStartTime(videoSession)
			])),
			map(([response, videoSession, initialStartAt]) => ({
				...response.video,
				approval: this.shapeApprovalModel(response.video.approval, true),
				canEditVideo: response.canEditVideo,
				canDownloadInEditor: response.canDownloadInEditor,
				categories: response.categories,
				initialStartAt,
				preposition: this.shapePreposition(response.video.preposition),
				reportsEnabled: response.reportsEnabled,
				sessionId: videoSession ? videoSession.id : null,
				status: !this.accountLicense.mediaViewingAllowed ? VideoStatus.VIEWING_HOURS_NOT_AVAILABLE : response.video.status,
				supplementalContent: response.mediaContent,
				transcriptionFiles: response.transcriptionFiles,
				userDetails: response.videoUser
			})),
			tap(initialVideo => this.videoSubject$.next(initialVideo)),
			catchError((response: HttpErrorResponse) => {
				const reason = response?.error?.reason;

				// if public video with invalid password, redirect to the password input state
				if (reason === VideoPlaybackService.ERROR_REASON_INVALID_PASSWORD) {
					const isPasswordIncorrect: boolean = this.fromStateName === PUBLIC_VIDEO_PASSWORD_STATE_NAME;

					this.$state.go(PUBLIC_VIDEO_PASSWORD_STATE_NAME, { isPasswordIncorrect, videoId, playbackConfig: this.playbackConfig }, { location: false });
					return EMPTY;
				}

				this.SecurityRedirectHelper.onError(response, this.fromStateName);

				return EMPTY;
			})
		);
	}

	private shapeApprovalModel(approval: any, setInitStatus?: boolean): ApprovalModel {
		if(!approval) {
			return;
		}
		return {
			...approval,
			initialStatus: setInitStatus ? approval.status : undefined
		};
	}

	private getVideoPlayableStatus(video: any): Observable<VideoStatus> {
		if (!video) {
			return of(null);
		}

		switch (video.status) {
			case VideoStatus.DOWNLOADING_FAILED:
			case VideoStatus.PROCESSING:
			case VideoStatus.PROCESSING_FAILED:
			case VideoStatus.RECORDING_UPLOADING_FAILED:
			case VideoStatus.UPLOAD_FAILED:
				return of(video.status);

			case VideoStatus.INGESTING:
			case VideoStatus.UPLOADING:
			case VideoStatus.UPLOADING_FINISHED:
			case VideoStatus.RECORDING_STOP_RECORDING:
			case VideoStatus.RECORDING_FINISHED:
				return of(VideoStatus.UPLOADING);

			case VideoStatus.NOT_UPLOADED:
				return video.isReplacing ? of(VideoStatus.PROCESSING) : of(VideoStatus.UPLOADING);

			case VideoStatus.VIEWING_HOURS_NOT_AVAILABLE:
				return of(VideoStatus.VIEWING_HOURS_NOT_AVAILABLE);

			case VideoStatus.READY:
			case VideoStatus.READY_BUT_PROCESSING_FAILED:
				video.playableStatus = VideoStatus.READY;
				break;
		}

		// may be ready, but no playbacks supplied for this device
		if (!(video.playbacks || []).length) {
			return of(VideoStatus.DEVICE_NOT_SUPPORTED);
		}

		if (video.playableStatus === VideoStatus.READY) {
			if (video.isProcessing) {
				return this.getVideoPlayableStatusForReadyWhileProcessing(video);
			}


			return of(VideoStatus.READY);
		}

		return of(null);
	}

	private getVideoPlayableStatusForReadyWhileProcessing(video: any): Observable<VideoStatus> {
		return from(
			Promise.all([
				this.MediaFeatures.getFeatures(),
				this.VideoPlayerAdapter.convertToModernPlayerPlaybacks(video.id, ResourceType.Video, video.playbacks, video.isLive, video.sourceType, video.is360)
			])
				.then(([mediaFeatures, modernPlaybacks]) => {
					const isPlaybackSupportedByPlayer = this.SupportedPlaybacks.isPlaybackSupported(modernPlaybacks, !mediaFeatures.enableFlashPlayback);

					return isPlaybackSupportedByPlayer ?
						VideoStatus.READY :
						VideoStatus.PROCESSING;
				})
		);
	}

	private getVideoPlayerParams(videoId: string): Observable<IVideoPlaybackParams> {
		return this.rawParams$.pipe(
			filter(params => params.videoId === videoId),
			map(params => this.shapeParams(params)),
			tag<IVideoPlaybackParams>('getVideoPlayerParams')
		);
	}

	private getVideoSession(): Observable<any> {
		return combineLatest([this.videoId$, this.playlistId$])
			.pipe(
				first(),
				switchMap(([videoId, playlistId]) =>
					playlistId ?
						of(null) :
						from(this.VideoService.getVideoSession(videoId))
				),
				tag<any>('getVideoSession')
			);
	}

	public getPush$(videoId: string): Observable<IPushObservableMessage> {
		const userId = this.UserContext.getUser().id;
		const $userPush = userId ?
			this.PushBus.getObservable(userId, USER_VIDEO_ROUTE, {
				VideoRated: e => of({ videoUserRating: e.rating })
			}) :
			EMPTY;
		const updatePlaybacks = (ignoreIfPlayerInUse?: boolean) => {
			return of(ignoreIfPlayerInUse)
				.pipe(
					switchMap(ignore => ignore ?
						this.preventPlaybackUpdates$ :
						of(false)
					),
					first(),
					switchMap(preventPlaybackUpdates => from(this.retryGetPlaybackUntilSuccess(preventPlaybackUpdates)))
				);
		};
		const updatePlaybacksOnlyIfPlayerNotInUse = () => updatePlaybacks(true);
		const updatePlaybacksIfReady = ({ status }) => status === VideoStatus.READY ? updatePlaybacks() : of(null);

		const $videoPush = this.PushBus.getObservable(videoId, VIDEO_PLAYBACK_ROUTE, {
			VideoAnalyzed: updatePlaybacksIfReady,

			VideoApprovalStatusUpdated: () => {
				return from<any>(this.ApprovalProcessService.getVideoApprovalStatus(this.videoSubject$.getValue().id))
					.pipe(
						map<any, any>(response => {
							const approval = response.videoApprovalStatus;
							const isInitStatus = !approval.approvalProcessId && approval.status === ApprovalStatus.APPROVED;

							return {
								approval: this.shapeApprovalModel(response.videoApprovalStatus, isInitStatus),
								isActive: response.isActive
							};
						})
					);
			},

			DownloadingVideoFailed: () => {
				return of({
					status: VideoStatus.DOWNLOADING_FAILED
				});
			},

			ImageStoringFailed: data => {
				return data === ImageContext.VideoEdit ?
					of({
						thumbnailCfg: this.VideoService.getThumbnailCfg({})
					}) :
					of(null);
			},

			VideoCopyFinished: data => {
				return concat(
					of({
						thumbnailUri: data.thumbnailUri
					}),
					updatePlaybacksOnlyIfPlayerNotInUse());
			},

			TranscodingPresetProgressed: ({ overallProgress, isProcessing }) => of({ overallProgress, isProcessing }),

			OriginalVideoInstanceReplaced: updatePlaybacksIfReady,

			OriginalVideoInstanceSwitched: updatePlaybacksIfReady,

			VideoThumbnailSet: ({ overallProgress, isProcessing }) => of({ overallProgress, isProcessing }),

			OriginalVideoInstanceStoringFinished: ({ overallProgress, isProcessing }) => {
				return of({ overallProgress, isProcessing });
			},

			VideoConferenceStatusUpdated: data => {
				return of({
					recordedDuration: this.DateParsers.parseTimespan(data.recordedDuration),
					status: data.status,
					videoConference: {
						...this.videoSubject$.getValue().videoConference,
						reason: data.reason
					}
				});
			},

			VideoDetailsSaved: data => {
				if (!this.SecurityContext.checkAuthorization('media.view') && data.uploaderUserId !== this.UserContext.getUser().id) {
					this.VideoService
						.getVideoPlayback(data.id)
						.catch(err => {
							if (err.status === 401) {
								this.$state.go('portal.media');
							}
						});
				}

				return of(data);
			},

			VideoEditing: data => {
				return of({
					...data,
					approval: {
						status: data.approvalStatus,
						initialStatus: data.approvalStatus
					},
					status: VideoStatus.PROCESSING
				});
			},

			VideoFastStartSet: updatePlaybacksIfReady,

			VideoInstanceStoringFinished: updatePlaybacksOnlyIfPlayerNotInUse,

			VideoLocked: () => {
				return of({
					isActive: false,
					legalHold: true
				});
			},

			VideoProcessingFailed: data => {
				return of({
					status: data.status
				});
			},

			VideoReplacing: data => {
				return of({
					...data,
					status: VideoStatus.UPLOADING
				});
			},

			VideoReplaced: data => of({ userTags: data.userTags }),

			VideoReverted: updatePlaybacks,

			VideoThumbnailSheetsSet: data => {
				return of({
					thumbnailCfg: this.VideoService.getThumbnailCfg(data.thumbnailCfg),
					overallProgress: data.overallProgress,
					isProcessing: data.isProcessing
				});
			},

			VideoTranscoded: updatePlaybacksOnlyIfPlayerNotInUse,

			VideoTranscodingOnDemand: data => {
				return of({
					transcodeOnDemandInitiator: data.initiator
				});
			},

			VideoUnlocked: () => {
				return from(this.retryGetPlaybackUntilSuccess())
					.pipe(
						map(value => {
							const approval: any = value.approval || {};

							if (approval.status !== ApprovalStatus.APPROVED) {
								value.approval = {
									...approval,
									status: ApprovalStatus.REQUIRES_APPROVAL
								};
							}

							return value;
						})
					);
			},

			VideoUploadingFailed: () => {
				return of({
					status: VideoStatus.UPLOAD_FAILED
				});
			},

			VideoUploadingFinished: () => {
				return of({
					status: VideoStatus.PROCESSING
				});
			},

			VideoConferenceFinished: () => {
				return of({
					status: VideoStatus.UPLOADING
				});
			},

			RootVideoCommentAdded: data => {
				const newComment = {
					...data,
					id: data.commentId,
					date: this.DateParsers.parseUTCDate(data.when),
					canRemoveVideoComment: this.authorizedDeleteComment || data.userId === this.UserContext.getUser().id,
					childComments: [],
					showReplies: true
				};

				return this.videoSubject$.pipe(
					first(),
					map(video => ({
						commentCount: video.commentCount + 1,
						comments: video.comments.concat(newComment)
					}))
				);
			},

			VideoCommentRemoved: data => {
				return this.videoSubject$.pipe(
					first(),
					map(video => ({
						comments: this.updateComment(video?.comments, data.commentId, data.parentCommentId, comment => ({
							...comment,
							isRemoved: true,
							username: null,
							firstName: null,
							lastName: null
						}))
					})));
			},

			ChildVideoCommentAdded: data => {
				const parentId: string = data.parentCommentId;
				const childComment = {
					...data,
					id: data.childCommentId,
					date: this.DateParsers.parseUTCDate(data.when),
					canRemoveVideoComment: this.authorizedDeleteComment || data.userId === this.UserContext.getUser().id
				};

				return this.videoSubject$.pipe(
					first(),
					map(video => ({
						commentCount: video?.commentCount + 1,
						comments: this.updateComment(video?.comments, parentId, null, parentComment => ({
							...parentComment,
							childComments: [
								...(parentComment.childComments || []),
								childComment
							]
						}))
					})));
			},

			VideoRated: data => of({
				averageRating: data.averageRating,
				ratingCount: data.ratingCount
			}),

			VideoChaptersSaved: data => this.videoSubject$.pipe(
				first(),
				map(video => {
					if(!video?.chapterInfo) {
						return;
					}
					const chapterInfo = video.chapterInfo;
					const chapters = this.VideoService.shapeChapters(data.chapters, data.chapterUri, data.chapterThumbnailUri);
					chapterInfo.setChapters(chapters);

					return {
						chapterInfo,
						downloadUrlForChapterInfoFile:  data.downloadUrlForChapterInfoFile
					};
				})
			),

			ImageStoringFinished: data => of({
				thumbnailUri: data.thumbnailUri
			})
		});

		return merge($userPush, $videoPush);
	}

	public mergeVideoPartial(videoPartial: any): void {
		if (videoPartial) {
			this.videoSubject$.next(
				Object.assign(
					{},
					this.videoSubject$.getValue(),
					videoPartial
				)
			);
		}
	}

	private retryGetPlaybackUntilSuccess(preventPlaybackUpdates?: boolean): Promise<any> {
		return retryUntilSuccess(
			() => Promise.resolve(this.VideoService.getVideoPlayback(this.videoSubject$.getValue().id)),
			undefined,
			response => !!response.video.playbacks && (response.video.status === VideoStatus.READY || response.video.status === VideoStatus.READY_BUT_PROCESSING_FAILED), // playback ready
			() => false //error
		)
			.then(response => {
				const { chapterInfo, approval }: {chapterInfo: VideoChaptersModel; approval: ApprovalModel}
					= this.videoSubject$.getValue(); //do not overwrite chapterInfo/approval when reloading video

				if (preventPlaybackUpdates) {
					delete response.video.playbacks;
				}

				return Object.assign(response.video, {
					approval,
					canEditVideo: response.canEditVideo,
					chapterInfo,
					status: !this.accountLicense.mediaViewingAllowed ? VideoStatus.VIEWING_HOURS_NOT_AVAILABLE : response.video.status
				});
			})
			.catch(err => this.SecurityRedirectHelper.onError(err, this.fromStateName));
	}

	private setPlaylistId(playlistId: string): Promise<void> {
		if (!playlistId) {
			this.playlistSubject$.next(null);
			return;
		}

		return this.PlaylistService
			.getPlaylist(playlistId)
			.then(response => this.playlistSubject$.next(response))
			.catch(err => this.SecurityRedirectHelper.onError(err, this.fromStateName));
	}

	private shapeParams(params: IVideoPlaybackParams): IVideoPlaybackParams {
		const { autoplay, startAt } = params;
		const parsedStartAt: number = startAt ?
			this.DateParsers.parseUrlTimespan(startAt) || 0 :
			undefined;

		return {
			...params,
			autoplay: !!(autoplay || params.playlistId),
			parsedStartAt
		};
	}

	private registerPlaylistPushListeners(): IUnsubscribe {
		return this.PushBus.subscribe(this.UserContext.getUser().id, {
			PlaylistCreated: (data: any) => {
				const playlist: IVideoPlaylist = {
					id: data.playlistId,
					isNew: true,
					isSelected: false,
					name: data.name,
					videoIds: [],
					date: data.createdBy.when
				};

				const existingPlaylists: IVideoPlaylist[] = this.playlistCollectionSubject$.value;
				this.playlistCollectionSubject$.next(existingPlaylists.concat(playlist));
			}
		});
	}

	private shapePreposition(preposition: any): IVideoPreposition {
		if (!preposition) {
			return null;
		}

		return {
			...preposition,
			lastDateTime: this.DateParsers.parseUTCDate(preposition.lastDateTime)
		};
	}

	private updateComment(comments: any[], commentId: string, parentCommentId: string, mapFn: (_:any) => any): any {
		if(!comments) {
			return;
		}
		if(!parentCommentId) {
			return mapComment(comments, commentId, mapFn);
		}

		return mapComment(comments, parentCommentId, c => ({
			...c,
			childComments: mapComment(c.childComments, commentId, mapFn)
		}));

		function mapComment(cs: any[], id: any, fn: (_:any) => any): any[] {
			return cs.map(c => c.id !== id ? c : fn(c));
		}
	}
}
